// for Index swiper effect
$color_1: #fff0;
$font-family_1: var(--fancy-slider-font-family);

:root {
    --fancy-slider-theme-color: #fff;
    --fancy-slider-font-family: sans-serif;
    
    --fancy-slider-button-side-offset: 32px;
    --fancy-slider-button-mobile-side-offset: 12px;
    --fancy-slider-button-mobile-bottom-offset: 0px;

    --fancy-slider-head-font-size-s: 36px;
    --fancy-slider-head-font-size: 48px;
    --fancy-slider-head-font-size-b: 60px;
    --fancy-slider-title-font-size: 33px;
    
    --fancy-slider-title-font-family: $OpenSans-300;
    --fancy-slider-title-line-height: 1;

    /* Card */
    --card-transition-duration: 500ms;
    --card-transition-delay: 250ms;    /* delay background color */
    
    /* background */
    --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
    --fancy-slider-transition-background-delay: 1000ms;    /* delay background color */
    
    /* other */
    --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
    --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
    --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
    
    /* scaling */
    --fancy-slider-transform-scale-slide: 1;               /* before scale in slide - same as in js */
    --fancy-slider-transform-scale-img: 1;               /* before scale in img - same as in js */
    
    /* nav */
    --fancy-slider-transition-button: 400ms;        /*  */
    --fancy-slider-transition-button-arrow: 400ms;  /*  */
    --fancy-slider-transition-button-circle: 400ms; /*  */

    // Video
    --bs-aspect-ratio-mobile: 100%
}
    
@keyframes jump {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 10px;
    }
    100% {
        bottom: 0;
    }
}

@keyframes moveCircle {
    0%,
    100% {
        transform: translateY(-15%);
    }
    50% {
        transform: translateY(-5%);
    }
}
  
.animationItem {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
  
.animationItem.animated {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.index{
    // 內框
    &-content{
        @include setBackSet(top,cover);
        padding-top: 2em;
        padding-bottom: 2em;

        @include setStyleRwd(1400px){
            padding-left: 1em;
            padding-right: 1em;
        }

        @include setMaxRwd{
            background-size: auto;
            padding: 1.5em 0.5em;
        }
    }

    // 頁面使用
    &-page{
        &Wrapper{
            @extend %setFlex;
            @include setMaxRwd{
                display: block;
                width: 100%;
                // flex-direction: column;
                // justify-content: center;
            }
        }

        &Swiper{
            width: 100%;
            overflow: hidden;
            .swiper-wrapper{
                /* 通过改变animation-timing-function 制作弹性切换效果 */
                transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
            }

            // 共用
            .swiper-button-wrapper{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                top: -2em;
                .swiper-button-prev,
                .swiper-button-next{
                    @include setSize(47px,47px);
                    overflow: hidden;
                    
                    &::after {
                        display: none;
                    }

                    svg{
                        path{
                            fill: $colorMain;
                        }
                    }

                    &.swiper-button-disabled {
                        svg{
                            path{
                                fill: $colorSecondary;
                            }
                        }
                    }

                    @include setMinRwd{
                        position: static;
                    }
                }
            }
        }

        // 標題用
        &Head{
            
        }

        // 內文用
        &Article{
            @include setBackSet(top,cover);

            .btn{
                @include setMaxRwd{
                    &-wrap{
                        margin-top: 0;
                        margin-bottom: 0.5em;
                        padding-right: 0.5em;
                    }
                }
            }

        }

        &Service{
            &-article{
                .articlebox{
                    --min-size: 16;
                    --max-size: 18;
                    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    padding-bottom: get-vw(70px);
                }

                .hellobabybox{
                    text-align: center;
                    animation: fadeInUser 2s ease-in-out alternate infinite;
                    @include setMaxRwd{
                        max-width: 9em;
                        margin: auto;
                    }
                }

                @include setMinRwd{
                    display: grid;
                    grid-template-columns: 0.7fr auto;
                    justify-content: flex-end;
                    .hellobabybox{
                        padding-right: get-vw(40px);
                    }
                }
            }

            .index-pageWrapper{
                @include setMinRwd{
                    margin-top: get-vw(45px);
                }
            }

            &-decoration-lefttop{
                .starbox{
                    position: absolute;
                    top: calc(get-vw(-120px));
                    @include setMaxRwd{
                        max-width: 5em;
                        top: calc(get-vw-mobile(-100px));
                    }
                }
            }

            &-grid{
                margin-top: get-vw(60px);
                margin-bottom: get-vw(120px);
                background: url('../images/index-pageSer-grid-img.jpg') no-repeat center;
                background-size: 100%;

                @include setMaxRwd{
                    background-position: bottom right;
                    background-size: 1800px;
                }

                &-middle{
                    .card{
                        background: transparent;
                        margin-bottom: 1.75em;

                        @include setStyleMinRwd(1600px){
                            padding-left: calc(58px / 2);
                            padding-right: calc(58px / 2);
                        }

                        &-title{
                            display: inline-block;
                            --min-size: 17;
                            --max-size: 22;
                            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                            font-weight: 900;
                            padding-bottom: 0.45em;
                            margin-bottom: 0.45em;
                            text-align: center;
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(81px,2px);
                                background-color: #000;
                                margin: 0.45em auto auto;
                            }
                        }

                        &-text{
                            color: #666;
                            @include setMaxRwd{
                                padding-left: 1em;
                                padding-right: 1em;
                            }
                        }

                        &-box{
                            background-color: #ffeed7;
                            box-shadow: 0 10px 0px 2px #f6e3c9;
                            border-radius: 45% 45% 50% 50%/45% 45% 40% 40%;
                            overflow: hidden;
                            padding-left: 3em;
                            padding-right: 3em;
                            padding-bottom: 3em;
                            padding-top: 2.5em;
                            @include setWidthRwd(1101px,1500px){
                                padding-bottom: 2em;
                            }

                            @include setMaxRwd{
                                padding-left: 1em;
                                padding-right: 1em;
                                padding-top: 1em;
                                padding-bottom: 1.5em;
                            }
                        }

                        &-img-top{
                            padding: 0em 0.5em 0;
                        }

                        &-img-circle{
                            position: relative;
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            border-radius: 50%;
                            @include setSize(100%,0);
                            padding-bottom: 100%;
                            overflow: hidden;
                            border: #ffeed7 4px solid;
                            box-shadow: -1px 2px 0px -1px #fff;
                        }

                        &-body{
                            padding-left: 0;
                            padding-right: 0;
                        }

                        &:nth-child(2){
                            .card-box{
                                background-color: #cbe6e6;
                                box-shadow: 0 10px 0px 2px #bedada;
                            }
                            .card-img-circle{
                                border-color: #cbe6e6;
                                box-shadow: 1px 2px 0px -1px #fff;
                            }
                        }

                        &:nth-child(3){
                            .card-box{
                                background-color: #e8f0c1;
                                box-shadow: 0 10px 0px 2px #e1e8c0;
                            }
                            .card-img-circle{
                                border-color: #e8f0c1;
                            }
                        }
                    }
                }

                @include setWidthRwd(1101px,1500px){
                    padding: 0 3em 0 3em;
                }
            }

            @include setMaxRwd{
                .title-wrap{
                    padding-top: 1em;
                }
            }
        }

        &News{
            padding-bottom: 0;

            &::before{
                content: '';
                display: block;
                @include setSize(100%,176px);
                @include setBackSet(top,100%);
                background-image: url('../images/index-pageNews-bgTop.png');
                position: absolute;
                top:0
            }

            &-decoration{
                position: absolute;
                z-index: 1;
                &-left{
                    left: get-vw(-110px);
                    top: get-vw(-110px);
                }

                &-right{
                    right: get-vw(-170px);
                    top: get-vw(-170px);
                }

                @include setStyleRwd(1500px){
                    &-left{
                        width: 5em;
                        left: 0;
                    }
    
                    &-right{
                        width: 8em;
                        right: 0;
                    }
                }

                @include setMaxRwd{
                    width: 3.5em;
                }
            }

            .index-pageWrapper{
                background-color: #f7f5f1;

                @include setMinRwd{
                    margin-top: get-vw(50px);
                    padding-bottom: get-vw(80px);
                }
            }

            @include setStyleRwd(1500px){
                padding-left: 0;
                padding-right: 0;
                .index-pageWrapper{
                    padding-left: 4em;
                    padding-right: 4em;
                }
            }

            @include setMaxRwd{
                .index-pageWrapper{
                    padding-left: 1.5em;
                    padding-right: 1.5em;
                    padding-bottom: 1.5em;
                    .btn-wrap{
                       margin-bottom: 0; 
                    }
                }
            }
        }
    }
}

@keyframes fadeInUser {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
}

@keyframes slideInDownUser {
    from {
      transform: translate3d(0, -15%, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }

// 
.titlebox{
    display: inline-block;
    padding-right: get-vw(70px);
    padding-top: get-vw(75px);
    border-right: $colorBase 1px solid;
}

// 星星裝飾
.starbox{
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 12em;
    .starlist{
        &-left{
            animation: slideInDownUser 0.75s ease alternate infinite;
        }

        &-middle{
            top: -3em;
            animation: slideInDownUser 0.75s 0.2s ease alternate infinite;
        }

        &-right{
            top: -1.9em;
            animation: slideInDownUser 0.75s 0.4s ease alternate infinite;
        }
    }

    // 位置&動畫
    &-animated{
        position: absolute;
        top: calc(get-vw(-120px));
        @include setMaxRwd{
            max-width: 5em;
            top: calc(get-vw-mobile(-100px));
        }
    }
}

/* swiper index banner */
.swiper-bannerIn{
	width: 100%;
	height: 100%;
    overflow: hidden;

    .swiper-container {
        width: 100%;
        height: 100%;
    }
    
    .swiper-image {
        height: 100%;
    }

    .swiper-image-inner {
        background-size: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-blend-mode: overlay;
    }

    .swiper-slide{
        transition: all 1s linear;
        transition-delay: 0.5s;
        opacity: 0;
        overflow: hidden;
    }

    .swiper-slide.swiper-slide-next {
        opacity: 0;
    }

    .swiper-slide.swiper-slide-active{
        opacity: 1;
        width: 100% !important;
    }

    .swiper-slide.swiper-slide-active .swiper-image{
        overflow: hidden;
    }
    
    .swiper-image.swiper-image-active{
        img{
            width: 100%;
            @include setMaxRwd{
                height: 14em;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .swiper-image-inner.swiper-image-active {
        transition: all 1s linear;
        transition-delay: 1s;
        background-blend-mode: multiply;
    }

    /* Arrows */
    .swiper-button-prev,
    .swiper-button-next {
        width: 39px;
        transition: var(--fancy-slider-transition-button);
        outline: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        cursor: pointer;

        &::after{
            display: none;
        }

        @include setMaxRwd{
            width: 40px;
            opacity: 0.75;
            svg {
                image{
                    height: 45px;
                }
            }
        }
    
        svg {
            display: block;
            transition: var(--fancy-slider-transition-button);
        }
    }

    .swiper-button-prev{
        left: 0;
        @include setMaxRwd{
            left: -2%;
        }
    }

    .swiper-button-next{
        right: 0;
        @include setMaxRwd{
            right: -2%;
        }
    }

    // 
    .swiper-pagination{
        width: 100%;
        position: absolute;
        text-align: center;
        transition: 300ms;
        transform: translate3d(0, 0, 0);
        z-index: 10;
        &-bullet {
            width: 8px;
            height: 8px;
            background: #000;
            opacity: 1;
            &-active {
                opacity: 1;
                background: transparent;
                transform: scale(2);
                border: #000 2px solid;
            }
        }

        @include setMinRwd{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            width: calc(100% - 4em) !important;
            min-height: 8em;
            bottom: auto !important;
            top: 50%;
            left: 2em !important;
            .swiper-pagination-bullet{
                margin-top:4px !important;
                margin-bottom: 4px !important;
            }
        }
    }
      
}

.page{
    // 共用Banner形象圖
    &-banner{
        width: 100%;
        overflow: hidden;
        z-index: 1;

        @include setWidthRwd(900px,1100px){
            margin-top: 1%;
        }

        &.index{
            overflow: visible;
            z-index: 3;
        }

        &-main{
            // @include mobile{
            //     img{
            //         height: 100px;
            //         object-fit: cover;
            //     }
            // }
        }

        &-style{
            max-width: 16rem;
            position: absolute;
            right: 2.75rem;
            bottom: -8rem;
            z-index: 3;
            @include setWidthRwd(1081px,1600px){
                max-width: 12rem;
                bottom: -4rem;
                right: 4rem;
            }

            @include setMaxRwd{
                max-width: 6.5rem;
                bottom: -5rem;
                right: 0.5rem;
            }
        }
    }

    // 內容
    &Grid{
        display: grid;
        grid-column-gap: 0;
        grid-auto-rows: 0fr 0fr 0fr;

        &-list{
            &-frAuto{
                grid-template-columns: repeat(2, auto) 1fr
            }
            
            &-1to1{
                grid-template-columns: 1fr 1fr;
            }

            &-2to1{
                grid-template-columns: 2fr 1fr 1fr;
            }

            &-3to1{
                grid-template-columns: 1fr 1fr 1fr;
            }
            
            &-avg{
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            &-1to2{
                grid-template-columns: 1fr 1fr 2fr;
            }
        }

        @include pad1080{
            grid-template-columns: auto !important;
        }
    }

    &Flex{
        @extend %setFlex;
    }
}
