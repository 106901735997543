.about{
    &-content{
        margin-top: 1em;
        margin-bottom: 1em;
        @include setMinRwd{
            margin-top: get-vw(50px);
            margin-bottom: get-vw(70px);
        }
        
        @include setWidthRwd(1101px,1500px){
          padding-left: get-vw(50px);
          padding-right: get-vw(70px);
        }

        @include setMaxRwd{
          padding-left: 0.5em;
          padding-right: 0.5em;
          .index-pageNews-decoration{
            top: -3em;
          }
        }
    }

    &-bgcolor{
      background-color: #f7f5f1;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 176px;
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100%;
        background-image: url("../images/index-pageNews-bgTop.png");
        position: absolute;
        top: -2em;
      }
      
      @include setMinRwd{
        padding-top: 3em;
        padding-bottom: 5em;
        &::before {
          top: -3em;
        }
        &:last-child{
          padding-bottom: 3em;
        }

        .index-pageNews-decoration-left{
          width: 5em;
        }

        .index-pageNews-decoration-right{
          width: 6em;
          top: get-vw(-120px);
        }
      }

      @include setMaxRwd{
        margin-top: 3em;
      }
      
      &:last-child{
        margin-bottom: 0;
      }
    }

    // 內容
    &-txt{
      --min-size: 17;
      --max-size: 18;
      --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
      font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
      padding-left: 1em;
      padding-right: 1em;
      @include setMinRwd{
        padding-left: 1.75em;
      }
    }

    /** Circle Styles */
    &circle {
      position: relative;
      display: block;
      margin: 2em 0;
      background-color: transparent;
      color: #222;
      text-align: center;

      @include setMinRwd{
        &:after,
        &:before {
          content: "";
          display: block;
          padding-bottom: 100%;
          width: 100%;
          height: 0;
          @include radius(50%);
        }

        &:after {
          background-color: #fff;
          position: relative;
          z-index: 1;
        }

        &::before {
          background-color: #cbe6e6;
          position: absolute;
          top: 0.35em;
          left: 0.35em;
          z-index: 0;
        }
      }

      @include setMaxRwd{
        background-color: #fff;
        padding: 1em;
        margin-top: 0.75em;
        margin-bottom: 0;
        height: calc(100% - 2em);
        box-shadow: 7px 7px 0px 0px #cbe6e6;
      }

      
      &__inner {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        @include setMinRwd{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
        }
      }
    
      &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include setMinRwd{
          // justify-content: flex-start;
          padding-top: 1.5em;
        }
      }

      &__icon{
        margin-bottom: 0.55em;
        svg, image, .icon-svg{
          width: 4em;
          height: 4em;
        }

        @include setMaxRwd{
          margin-top: 0.75em;
        }
      }

      &__head{
        --min-size: 18;
        --max-size: 21;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        @extend .fw-900;
        margin-bottom: 0.45em;
      }
    
      &__content {
        font-size: 15px;
        text-align: center;
        @include setMinRwd{
          padding: 0 3em;
        }

        @include setMaxRwd{
          margin-bottom: 0.75em;
        }
      }
    }

    // 圓圈文字使用
    &cirlce-list{
      @include setWidthRwd(1101px,1500px){
        display: grid;
        grid-column-gap: 0;
        grid-auto-rows: 0fr 0fr 0fr;
        grid-template-columns: repeat(3, 33%);
        & > * {
          width: 100%;
        }

        .aboutcircle__wrapper {
          justify-content: center;
          padding-top: 0;
        }
      }
    }

    // 文字卡片使用
    &-qa{
      &list{
        &-col{
          margin-bottom: 1.75em;
        }
      }

      &card{
        border: #dfdbd2 3px solid;
        @include radius(1.5em);
        padding: 2em 1em;
        max-width: 100%;
        margin-bottom: 0.75em;
        margin-left: 0;
        margin-right: 0;

        @include setMinRwd{
          padding-top: 3em;
          padding-left: 1.6em;
          padding-right: 1.6em;
          margin-left: 0.25em;
          margin-right: 0.25em;
          height: 100%;
        }

        .card-head{
          @extend %setFlex;
          align-items: flex-end;
          justify-content: space-between;
          margin-bottom: 0.5em;
          @include setMinRwd{
            padding-top: 3em;
            padding-right: 6em;
          }
        }

        .card-title{
          @include setMaxRwd{
            width: 100%;
            text-align: center;
          }

          @include setMinRwd{
            padding-right: 1em;
          }
        }
        
        .card-img-top{
          margin-bottom: 0.25em;
          text-align: center;

          @include setMinRwd{
            width: auto;
            position: absolute;
            right: 0;
            top: -1em;
          }

          @include setMaxRwd{
            svg,image{
              width: 3em;
              height: 3em;
            }
          }
        }
      }
    }
}

.icon-img,
.icon-svg{
  width: 4em;
  height: 4em;
}

.icon-svg-sm{
  width: 5em;
}

// 
.intro_head{
  display: inline-block;
  color: $colorMain;
  --min-size: 18;
  --max-size: 26;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  letter-spacing: 0.15em;
  line-height: 1.5;
  margin-bottom: 5px;
  @extend .fw-600;
  @include setMinRwd{
    background: linear-gradient(transparent 60%, #f6df74 60%);
    line-height: 1.7em;
  }
}

.about-intro-head{
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(-98px/2);
    left: 50%;
    width: 150px;
    height: 98px;
    margin-left: 320px;
    background: url('../images/pageimg-service001.png') left top no-repeat;
    // background: url('../images/page-decoration-hellobaby.png') left top no-repeat;
    background-size: contain;
    image-rendering: -webkit-optimize-contrast;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    // animation: fadeInUser 2s ease-in-out alternate infinite;

    @include setMaxRwd{
      left: auto;
      right: 0;
    }

    @include pad{
      width: 80px;
    }
  }

  @include setMaxRwd{
    margin-top: 1em;
  }
}

// 五個圓
.circleGrid-5{
  @include setMinRwd{
    display: grid;
    grid-column-gap: 0;
    grid-auto-rows: 0fr 0fr 0fr;
    grid-template-columns: repeat(5, 20%);
    & > * {
      width: 100%;
    }
    .aboutcircle__icon{
      svg,  image {
        width: 5em;
        height: 5em;
      }
    }
  }

  @include setWidthRwd(1101px,1500px){
    grid-template-columns: repeat(4, 25%);
    .aboutcircle {
      margin: 2em 0 0;
    }
  }
}

// 
.intro-title {
  --min-size: 22;
  --max-size: 31;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-weight: bold;
  padding-top: 4px;
  &-sm{
    --min-size: 22;
    --max-size: 26;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  }
}

// 
.c-btn01 {
  display: flex;
  justify-content: center;

  &__txt {
    position: relative;
    line-height: 1.5;
    color: #483b35;
    font-weight: 700;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  &__ico {
    display: block;
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    width: 22px;
    height: 22px;
    margin: auto 0;
    border-radius: 50%;
    background-color: #e88f75;
    &::before {
      display: inline-block;
      width: 12px;
      height: 12px;
      vertical-align: middle;
      background: no-repeat center/contain;
      background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2012%2012%22%20style%3D%22enable-background%3Anew%200%200%2012%2012%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23fff%7D%3C%2Fstyle%3E%3Cg%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1889%22%20class%3D%22st0%22%20d%3D%22M11.1%2C5.2L7.4%2C1.3C7%2C0.9%2C6.4%2C0.9%2C6%2C1.3L5.9%2C1.3c-0.4%2C0.4-0.4%2C1.1%2C0%2C1.5l2%2C2.1H1.5%20C1%2C5%2C0.5%2C5.5%2C0.6%2C6c0%2C0.5%2C0.4%2C0.9%2C1%2C1h6.4l-2%2C2.1c-0.4%2C0.4-0.4%2C1.1%2C0%2C1.5C6.1%2C10.9%2C6.3%2C11%2C6.6%2C11h0c0.3%2C0%2C0.5-0.1%2C0.7-0.3l3.8-4%20C11.5%2C6.3%2C11.5%2C5.7%2C11.1%2C5.2z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
      content: "";
      transition: all .3s cubic-bezier(.445,.05,.55,.95);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
    @media (min-width: 768px) {
      right: 45px;
      width: 25px;
      height: 25px;
      top: 5px;
      transition: all .3s cubic-bezier(.445,.05,.55,.95);
      transform: translateX(0);
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-height: 56px;
    padding: 12px 2.5em;
    line-height: 1.25;
    text-align: center;
    border-radius: 28px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 3px 0px 2px #eaeaea;
    
    --min-size: 16;
    --max-size: 18;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);

    @media (min-width: 768px) {
      max-width: 320px;
      min-height: 70px;
      padding: 12px 55px 9px;
      line-height: 1.5;
      border-radius: 35px;
      margin: 0 1em;
      &:hover{
        box-shadow: 0 2px 0px 2px #eaeaea;
        .c-btn01__ico {
          transform: translateX(5px);
        }
      }
    }

    @include setMaxRwd{
      margin-left: 5px;
      margin-right: 5px;
    }

    &_sm{
      background-color: rgba(234, 231, 231, 0.5);
      box-shadow: 0 3px 0px 2px #d4d4d4;
      padding-left: 0;
      padding-right: 0;
      max-width: 11em;
      min-height: 3.5em;
      .c-btn01__ico{
        right: 20px;
      }
      @media (min-width: 768px) {
        &:hover{
          box-shadow: 0 2px 0px 2px #d4d4d4;
        }
      }
    }
  }
}


// 
.support{
  width: 100%;
  background: url("../images/bg_circle_l.png") center 150px no-repeat;
  &-circle{
    width: 100%;
    &::before{
      content: '';
      display: block;
      width: 100vw;
      height: 100vw;
      background: url("../images/bg_circle_l.png") center 150px no-repeat;
      position: absolute;
      left: 0;
    }
  }

  &-circle-r{
    width: 100%;
    &::before{
      content: "";
      display: block;
      width: 100vw;
      height: 100%;
      max-height: 35em;
      background: url("../images/bg_circle_r.png") right 0px no-repeat;
      position: absolute;
      right: 0;
      bottom: 0;
      background-size: contain;
    }
  }
}

// 表格
.outlineBox {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0px 20px 10px -20px rgba(0,0,0,0.15);
  border-radius: 20px;
  position: relative;
  padding: 35px 60px 45px;
  dl {
    padding: 10px 0;
    border-top: 1px solid #cccccc;
    display: flex;
    &, dd{
      margin-bottom: 0;
    }

    &.outline-head{

    }
    
    &.outline-last {
      border-top: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
    }

    dt {
      min-width: 11em;
      padding: 0 10px;
      margin-right: 30px;
      border-right: 2px solid #655d5b;
      font-size: 16px;
    }
    dd {
      flex: 1;
      font-size: 15px;
      padding-top: 6px;
    }
  }

  @include setMinRwd{
    // width: 700px;
    max-width: 46em;
    dt {
      line-height: 40px;
    }
  }

  @include setMaxRwd{
    padding: 30px 5%;
    dl {
      display: block;
      dt {
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
        font-weight: bold;
      }
    }
  }
}

// hover effect
.imgMore {
  max-width: 2em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 2em;
  z-index: 3;
}

// gallery
.service__photo {
  z-index: 1;
  .card{
    border: $bgSecondary 1px solid;
    box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.3);
    background: #fff;
    height: 0;
    padding:8px 8px 30% 8px;
    
    img{
      @include radius(5px);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }

    .imgMore{
      top: 100%;
      opacity: 0;
      transition: all .4s ease;
    }
  }

  @include setMinRwd{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 15px;
    margin-top: 0;
    padding-left: 2em;
    padding-right: 2em;
    .card{
      padding-bottom:70%;
      margin: 0;
      
      &::before {
        content: "";
        width: calc(100% - 0.5em);
        height: calc(100% - 0.5em);
        background-color: rgba(73, 58, 62, 0.35);
        pointer-events: none;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        opacity: 0;
        z-index: 1;
        position: absolute;
        left: 0.25em;
        top: 0.25em;
        @include radius(10px);
      }

      &:hover{
        &::before{
          opacity: 1;
        }

        .imgMore{
          top: 0;
          opacity: 1;
          pointer-events: none;
        }
      }
    }
  }

  &__note {
    padding: 0em 2em;
    text-align: right;
    .icon {
      margin-top: -6px;
      margin-right: 1px;
    }
    svg {
      height: 1.25em;
    }
  }

  @include setMaxRwd{
    margin-bottom: 0;
  }
}


// 
.m-deco {
  position: absolute;
  z-index: -1;
  transform: rotate(-350deg);
  transform-origin: center;
  opacity: .8;
  animation: katakata2 6s step-start 0s infinite normal;
  z-index: 0;
  svg {
    display: block;
    height: 100%;
    transform: rotate(-98deg);
    transform-origin: center;
    width: 100%;
    fill: #eee;
  }

  &.red{
    opacity: .5;
    svg{
      fill: #f73636;
    }
  }

  &.orange{
    opacity: 0.4;
    svg{
      fill: #ff9200;
    }
  }

  &.main{
    opacity: 0.4;
    svg{
      fill: #34a9b2;
    }
  }

  &.gray{
    svg{
      fill: #e3e3e3;
    }
  }
}

@keyframes katakata2{
  0% {
    transform: translateY(2px) rotate(-6deg);
  }
  10% {
    transform: translate(0) rotate(0deg);
  }
  20% {
    transform: translate(0) rotate(6deg);
  }
  30% {
    transform: translateY(2px) rotate(0deg);
  }
  40% {
    transform: translateY(2px) rotate(-6deg);
  }
  50% {
    transform: translate(-2px) rotate(2deg);
  }
  60% {
    transform: translateY(-2px) rotate(0deg);
  }
  70% {
    transform: translate(0) rotate(-6deg);
  }
  80% {
    transform: translate(-2px) rotate(2deg);
  }
  90% {
    transform: translate(0) rotate(0deg);
  }
  100% {
    transform: translateY(2px) rotate(-6deg);
  }
}

@include setMinRwd{
  .m-deco  {
    width: 158px;
    height: 158px;
    right: -50px;
    top: -75px;
    &.gray{
      left: -30px;
      right: auto;
      top: auto;
      bottom: -35px;
      width: 125px;
      height: 125px;
    }
  }
}

@include setMaxRwd{
  .m-deco{
    width: 4em;
    height: 4em;
    bottom: 0em;
    &.main{
      right: 0;
      bottom: auto;
      top: -1em;
    }
  }
}