.news{
    // 列表
    &-card{
        .card{
            // &:nth-child(1){
            //     .tag-item-remark{
            //         background-color: #ffeed7;
            //     }
            // }

            // &:nth-child(3){
            //     .tag-item-remark{
            //         background-color: #e8f0c1;
            //     }
            // }

            // &:nth-child(4){
            //     .tag-item-remark{
            //         background-color: #d6c5f7;
            //     }
            // }

            // &:nth-child(5){
            //     .tag-item-remark{
            //         background-color: #fccad2;
            //     }
            // }

            // &:nth-child(6){
            //     .tag-item-remark{
            //         background-color: #f9f088;
            //     }
            // }
            
            &:hover{
                .tag-item-remark{
                    background-color: $colorMain !important;
                }
            }
        }

        @include setStyleRwd(1500px){
            padding-left: get-vw(50px);
            padding-right: get-vw(70px);
        }
    }

    &-card-wrap{
        .card{
            &-body,
            &-btn{
                width: 100%;
                border:#d9d9d9 2px solid;
                padding: 1.25em 1.5em;
                @include setMaxRwd{
                    padding-left: 0.75em;
                    padding-right: 0.75em;
                }
            }

            &-body{
                @include setMinRwd{
                    padding-top: 1.5em;
                    padding-bottom: 0.5em;
                }

                @include setMaxRwd{
                    padding-top: 0.5em;
                    padding-bottom: 0.25em;
                }

                display: flex;
                flex-direction: column;
                .card-time{
                    margin-top: auto;
                }
            }

            &-btn{
                background-color: transparent !important;
                color: #000 !important;
                justify-content: flex-start;
                margin-top: -2px;
                margin-bottom: 0;
                @include setMaxRwd{
                    padding-top: 0.5em;
                    padding-bottom: 0.7em;
                }
            }

            @include setMinRwd{
                margin-left: get-vw(40px);
                margin-right: get-vw(40px);
                flex: 0 0 calc((100% / 3) - (get-vw(40px) * 2));
                max-width: calc((100% / 3) - (get-vw(40px) * 2));
            }
        }
    }

    // 時間
    &-time{
        &list{
            border-color: #dfdbd2;
            border-width: 2px 0 2px 0;
            border-style: solid;
            padding-top: 0.75em;
            padding-bottom: 0.75em;
            padding-left: 0.5em;
        }

        &txt{
            color: #666666;
            --min-size: 16;
            --max-size: 18;
            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        }
    }

    // 詳細頁
    &-content{
        &-article{
            line-height: 1.75;
            padding-top: 1em;
            padding-bottom: 1em;
        }

        @include setStyleRwd(1500px){
            padding-left: get-vw(50px);
            padding-right: get-vw(70px);
        }
    }
}