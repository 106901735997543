/* #Navigation
================================================== */
.start-header {
	opacity: 1;
	transform: translateY(0);
	transition : all 0.3s ease-out;
    &.scroll-on {
        position: fixed;
        box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
        background-color: #fff;
        transition : all 0.3s ease-out;
        @include setMinRwd{
            .navbar-brand{
                img{
                    transition : all 0.3s ease-out;
                }
            }
        }
    }
    .container{
        max-width: 100%;
        img{
            margin: 0 !important;
        }
    }
}

.navigation-wrap{
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1050;
	transition : all 0.3s ease-out;
    @include setMaxRwd{
        padding: 5px 0;
        z-index: 1000;
        &.bg-light{
            background-color: transparent !important;
        }
    }
}

.navbar{
    @include fontSize(16px);

    @include setMinRwd{
        padding-top: 15px;
    }

    @include setStyleMinRwd(1400px){
        padding-left: 0.35em;
        padding-right: 0.35em;
    }

    @include setStyleMinRwd(1600px){
        padding-left: 1.75em;
        padding-right: 1.75em;
    }

    @include pad1080{
        justify-content: space-between;
    }

    // LOGO
    &-brand{
        padding: 0;
        margin: 0;
        img{
            display: block;
            transition : all 0.3s ease-out;
        }

        @include setWidthRwd(1300px,1600px){
            max-width: 13rem;
        }

        @include setWidthRwd(1080px,1299px){
            max-width: 14rem;
        }

        @include pad1080{
            max-width: 13rem;
        }

        @include mobileSmall{
            max-width: 10rem;
        }
    }

    // 
    &-light{
        .navbar-toggler-icon {
            width: 25px;
            height: 17px;
            background-image: none;
            position: relative;
            border-bottom: 1px solid #000;
            transition: all 300ms linear;
            &::after, 
            &::before{
                width: 25px;
                position: absolute;
                height: 1px;
                background-color: #000;
                top: 0;
                left: 0;
                content: '';
                z-index: 2;
                transition: all 300ms linear;
            }
            &::after{
                top: 8px;
            }
        }

        .navbar-nav{
            .nav-link {
                display: inline-flex;
            }
        }
    }

    // 主連結的外框
    &-collapse{
        flex-wrap: wrap;
        justify-content: space-between;

        @include setMinRwd{
            flex: auto;
            flex-wrap: nowrap;
        }

        // @include setWidthRwd(800px,1500px){
        //     width: 100%;
        //     margin-top: 0;
        //     flex-wrap: nowrap;
        // }

        @include setWidthRwd(1070px,1300px){
            padding-top: 0;
            padding-bottom: 0;
        }

        @include pad1080{
            position: fixed;
            @include setSize(100%,100vh);
            top: 0;
            left: -100%;
            background: #fff;
            transition: 0.15s;
            overflow-y: auto;
            overflow-x: hidden;
            max-width: 45vw;
            &,
            &>ul{
                z-index: 1000;
            }

            &>ul{
                position: relative;
                justify-content: flex-start !important;
            }

            &.show{
                display: block !important;
                left: 0;
            }
        }
    }

    &-overlay{
        display: block;
        @include setSize(100%,100vh);
        background-color: rgba(0,0,0,0.75);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        opacity: 0;
        visibility: hidden;

        &.active{
            opacity: 1;
            visibility: visible;
        }

        @include setMinRwd{
            display: block;
        }
    }

    // 手機用的漢堡
    &-toggler {
        float: right;
        border: none;
        padding-right: 0;
        &:active,
        &:focus {
            outline: none;
        }

        @include pad1080{
            display: block !important;
        }

        .hamburger-toggle{
            position: relative;
            display: inline-block;
            width: 50px;
            height: 50px;
            z-index: 11;
            .hamburger {
                position: absolute;
                transform: translate(-50%, -50%) rotate(0deg);
                left: 50%;
                top: 50%;
                width: 50%;
                height: 50%;
                pointer-events: none;
                span {
                    width: 100%;
                    height: 4px;
                    position: absolute;
                    background: $colorBlack;
                    border-radius: 2px;
                    z-index: 1;
                    transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0), background 0.2s cubic-bezier(0.77,0.2,0.05,1.0), all 0.2s ease-in-out;
                    left: 0px;
                    &:first-child{
                        top: 10%;
                        transform-origin: 50% 50%;
                        transform: translate(0% -50%) !important;
                    }
                    &:nth-child(2){
                        top: 50%;
                        transform: translate(0,-50%);
                    }
                    &:last-child{
                        left: 0;
                        top: auto;
                        bottom: 0;
                        transform-origin: 50% 50%;
                    }
                }

                &.active{
                    span {
                        position: absolute;
                        margin: 0;
                        &:first-child{
                            top: 45%;
                            transform: rotate(45deg);
                        }
                        &:nth-child(2){
                            left: 50%;
                            width: 0px;
                        }
                        &:last-child{
                            top: 45%;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }

        // 
        &.fixed{
            display: none !important;
            position: fixed;
            left: 43%;
            top: 2%;
            z-index: 1001;
            .hamburger-toggle{
                .hamburger{
                    span {
                        background-color: #fff;
                        &:first-child{
                            transform: rotate(40deg);
                        }

                        &:last-child{
                            transform: translateY(-18px) rotate(-40deg);
                        }
                    }
                }
            }
            
            &.active{
                display: block !important;
            }
            
        }
    }

    &-nav{
        // 主連結的link
        &.link{
            justify-content: space-between;
            
            .nav-item{
                @include setMinRwd{
                    text-align: center;

                    .nav-link{
                        display: block;
                        font-weight: 600;
                        line-height: 2.5;
                        padding: 0 10px !important;
                        margin: 0 20px;
                        transition: 0.25s;
                    }
                }

                @include setWidthRwd(1501px,1800px){
                    .nav-link{
                        margin: 0;
                        padding: 0 0.5em !important;
                    }
                }

                @include setWidthRwd(1081px,1500px){
                    .nav-link{
                        padding: 0 5px !important;
                        margin: 0 10px;
                    }
                }

                @include setWidthRwd(1081px,1300px){
                    .nav-link{
                        font-size: 16px;
                        padding: 0 !important;
                        margin: 0 5px;
                    }
                }

                &:hover,
                &.active{
                    .nav-link{}
                }
            }

            .dropdown-menu{
                box-shadow: none;
                background: transparent;
                &-list{
                    li{
                        .dropdown-item{
                            @include fontSize(16px);
                            background-color: transparent !important;
                            color: #000 !important;
                            line-height: 1.05;
                            letter-spacing: 0px;
                            @include setMinRwd{
                                white-space:normal;
                                padding: 10px 6px;
                                text-align: center;
                                &:hover{
                                    background-color:$colorMain !important;
                                    color: white !important;
                                }
                            }
                         }
    
                        .dropdown-menu-subContanier {
                            padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
                            .dropdown-item{
                                position: relative;
                                @include fontSize(14px);
                                text-align: center;
                                color: #666;
                                padding-left: 1.25rem;
                                &::before{
                                    content: '';
                                    display: block;
                                    @include setSize(10px,1px);
                                    background-color: #696969;
                                    position: absolute;
                                    top: 12.5px;
                                    left: 0;
                                }
                            }
    
                            @include setMaxRwd{
                                padding-top: 0;
                                margin-top: -2px;
                                padding-left: 20px;
                                max-height: 55vh;
                                overflow-x: hidden;
                                overflow-y: auto;
                                padding: 10px; 
    
                                position: static;
                                display: none;
                                opacity: 0;
                                visibility: hidden;
                                .dropdown-item{
                                    @include fontSize(15px);
                                    background-color: transparent !important;
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                }

                                &.active{
                                    display: block;
                                    opacity: 1;
                                    visibility: visible; 
                                }
                            }
                        }
                    }
                }
            }

            @include setMinRwd{
                position: relative;
                justify-content: center;
                flex: 1;
                padding-left: get-vw(60px);
                
                .nav-link{
                    text-transform: uppercase;
                    flex-direction: column;
                }

                .nav-item{
                    &:hover{
                        .dropdown-menu{
                            display: block;
                            opacity: 1;
                            visibility: visible;
                            left: 50%;
                            padding: 0 !important;
                            transform: translate3d(-50%, 0px, 0);
                            min-width: 12em;
                            max-height: 100vw;
                            .dropdown-menu-list{
                                width: 100%;
                                padding: 10px;
                                background: #f2f2f2;
                                max-height: 100vw;
                                @include radius(5px);
                                position: relative;
                                top: 5px;
                                &::before{
                                    content: "";
                                    display: block;
                                    @include setSize(70%,2px);
                                    background-color: #fff;
                                    @include radius(15px);
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                            }
                        }
                    }
                }

                .dropdown-item{
                    &:hover{
                        background-color: transparent;
                        color: #000;
                    }
                }
            }

            @include setWidthRwd(1081px,1600px){
                flex: 1;
                justify-content: space-around;
                // padding-left: 0;
            }

            @include pad1080{
                width: 100%;
                flex-direction: column;
                .nav-item{
                    padding-left: 1.5rem !important;;
                }
            }
        }

        //主連結的資訊
        &.info{
            @include setMinRwd{
                align-items: center;
                justify-content: center;
                .remark-social{
                    .icon{
                        margin-left: get-vw(8px);
                        margin-right: get-vw(8px);
                    }
                    svg, image{
                        width: 2em;
                        height: 2em;
                    }
                } 

                .nav-item-website{
                    margin-left: get-vw(40px);
                }
            }

            @include setWidthRwd(1081px,1600px){
                .remark-social{
                    svg, image{
                        width: 1.75em;
                        height: 1.75em;
                    }
                } 

                .nav-item-website{
                    margin-left: get-vw(0px);
                    .remark-social{
                        img{
                           max-width: get-vw(320px); 
                        }
                    }
                }
            }

            @include pad1080{
                display: none;
            }
        }
    }

    // 下拉
    &-mobile-icon{
        @include setSize(80px,40px);
        color: #000 !important;
        text-align: right;
        padding-right: 18px;
        position: absolute;
        right: 0;
        top: 5px;
        line-height: 40px;
        &::after {
            display: none;
        }

        &.show{
            .bi{
                transform: rotate(180deg);
            }
        }
    }
}

// 
.nav{
    &-link{
        position: relative;
        display: inline-block;
        color: $colorBase !important;
        
        --min-size: 18;
        --max-size: 20;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);

        font-weight: 500;
        padding: 5px 0 !important;
        transition: all 200ms linear;

        @include setMinRwd{
            line-height: 3;
        }

        @include pad1080{
            padding: 10px 0 !important;
        }

    }

    &-item{
        position: relative;
        transition: all 200ms linear;

        &.active,
        &:hover{
            .nav-link{
                color:$colorItem !important
            }
        }

        .icon {
            display: inline-block;
            vertical-align: top;
            flex: none;
            margin-top: -2px;

            .bi-caret-down-fill{
                font-size: 12px;
                color: $colorBlack;
                display: inline-block;
                vertical-align: middle;
                margin-top: -3px;
                margin-left: 1.5em;
            }
        }

        // -reset在新版編輯器的 .container img { margin: 1.4rem 0 1rem; }
        img{
            margin: 0 !important;
        }

        @include setWidthRwd(1701px,1800px){
            margin-left: 1.35em !important;
        }

        @include setWidthRwd(1000px,1200px){
            margin-left: 0.5em !important;
        }

        @include setMaxRwd{
            .dropdown-menu-list{
                li{
                    position: relative;
                    margin-bottom: 0.25em;
                    
                    .dropdown-item{
                        padding-left: 0;
                    }

                    .navbar-mobile-icon{
                        right: -1.3em;
                        top: -4px;
                    }
                }
            }
        }

        @include pad1080{
            &::after{
                display: none;
            }

            &::before {
                position: absolute;
                display: block;
                top: 24px;
                left: 5px;
                width: 11px;
                height: 1px;
                content: "";
                border: none;
                background-color: #000;
            }
        }
    }
}

// 共用
.remark{
    &-item{
        display: block;
        @include radius(10em);
        line-height: 2.25;
        border: #000000 1px solid;
        font-size: 1em;
        @extend .fw-500;
        padding: 2px 20px !important;
        text-align: center;
        &:hover{
            background-color: $colorMain;
            color: white
        }
    }

    &-wrapper{
        @extend %setFlex-center;
        .icon{
            margin-left: 1px;
            margin-right: 1px;
        }
    }

    &-list{
        &:not(:last-child){
            &::after{
                content: '';
                display: block;
                @include setSize(1px,15px);
                background-color: #bfbfbf;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            margin-right: 20px;
            padding-right: 20px;

            @include setMinRwd{
            }
        }
    }

    &-social{
        padding-top: 3px;
    }
}

/* #Primary style
================================================== */

.bg-light {
	background-color: #fff !important;
    transition: all 200ms linear;
}

.dropdown{
    &-menu {
        padding: 10px!important;
        margin: 0;
        font-size: 13px;
        letter-spacing: 1px;
        color: #212121;
        background-color: #fcfaff;
        border: none;
        border-radius: 3px;
        box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
        transition: all 200ms linear;
    }

    &-toggle{
        &::after {
            display: none;
        }
    }

    &-item {
        font-size: 14px;
        line-height: 1.5;
        transition: all 200ms linear;
        &:hover, 
        &:focus {
            color: #fff;
            background-color: $colorMain;
        }
    }
}

.nav-item{
    .dropdown-menu {
        transform: translate3d(0, 10px, 0);
        visibility: hidden;
        opacity: 0;
        min-width: 6.75em;
        max-height: 0;
        display: none;
        padding: 0;
        margin: 0;
        transition: all 200ms linear;
        &.show{
            display: block;
            opacity: 1;
            visibility: visible;
            max-height: 999px;
            transform: translate3d(0, 0, 0);
        }

        @include setMinRwd{
            @include radius(5px);
        }

        @include setMaxRwd{
            .dropdown-menu-list{
                li{
                    border-bottom: 1px solid #e4e4e4;
                    padding: 10px;
                }
            }

            &.show{
                padding: 0 !important;
            }
        }
    }
}

// 手機Header放置主ICON
.nabar-icon-mobile{
    @include setMaxRwd{
        display: flex !important;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0.5em;
        .icon{
            margin-left: 10px;
            svg, image{
                width: 1.75em;
                height: 1.75em;
            }
        }
    }
}

.toggle-search{
    position: relative;
    top: 2px;
    .icon-txt{
        --min-size: 18;
        --max-size: 26;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
    }

    .icon-search {
        margin-top: -8px;
    }
}


// 搜尋後的表單
.search-form {
    &List{
        position: absolute;
        top: 6.65rem !important;
        width: 100%;
        height: 100vh;
        background: rgba(0,0,0,.75);
        z-index: 1;
        transition: 0.5s;
        display: none;
        &.open {
            display: block;
        }

        @include setWidthRwd(1081px,1600px){
            top: 5rem !important;
        }

        @include setWidthRwd(1101px,1200px){
            top: 5.25rem !important;
        }

        @include setMaxRwd{
            top: 4.45rem !important
        }
    }

    &Input{
        position: absolute;
        top: 0px;
        right: 0%;
        width: 100%;
        max-height: 60px;
        overflow: hidden;
        background-color: #e1e4e7;
        max-width: 40em;

        fieldset {
            position: relative;
            margin: 0 60px 0 0;
            padding: 0;
            border: none;
        }

        input {
            display: block;
            height: 60px;
        }

        input[type="search"] {
            width: 100%;
            padding: 0 5px 0 22px;
            background: transparent;
            border: none;
        }

        input[type="submit"] {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 80px;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            color: white;
            background: $colorMain;
            border: none;
            cursor: pointer;
        }
    }
}