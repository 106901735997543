@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("//fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("//fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap");
@import url("//fonts.googleapis.com/css2?family=Mitr&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
@import url("//fonts.googleapis.com/css2?family=Arimo&display=swap");
@import url("//fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("//fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("//fonts.googleapis.com/css2?family=Raleway&display=swap");
.is-wrapper *, a, body {
  color: #231815;
  font-size: 17px;
  font-family: "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  line-height: 1.7;
  font-weight: 400;
}

input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.about-qacard .card-head, .pageFlex, .index-pageWrapper, .card-wrap, .box, .pagination-wrap, .mainContent, .cookies-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.card-time, .btn-wrap, .radio_style, .content-header-wrap, .remark-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.imgCenter-4-3 img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  margin: auto;
}

.font-size-1 {
  font-size: 87.5% !important;
}

.font-size-2 {
  font-size: 100% !important;
}

.font-size-3 {
  font-size: 112.5% !important;
}

.font-size-4 {
  font-size: 150% !important;
}

.font-size-5 {
  font-size: 175.5% !important;
}

.font-size-6 {
  font-size: 250% !important;
}

.font-size-7 {
  font-size: 275% !important;
}

.font-size-8 {
  font-size: 362.5% !important;
}

.font-size-9 {
  font-size: 450% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 1.75 !important;
}

.line-height-5 {
  line-height: 2 !important;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
  overflow-x: hidden;
}
body.fixed {
  overflow: hidden;
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

form {
  font-size: 17px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 17px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 17px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1080px) {
  .hide {
    display: block !important;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.mw-auto {
  max-width: none !important;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1150 {
  width: 100%;
  max-width: 1150px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1050 {
  width: 100%;
  max-width: 1050px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-950 {
  width: 100%;
  max-width: 950px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.wrapper-750 {
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.wrapper-700 {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.wrapper-650 {
  width: 100%;
  max-width: 650px;
  margin: auto;
}

.is-wrapper * {
  letter-spacing: 0px !important;
}

/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  position: fixed;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  background-color: #fff;
  transition: all 0.3s ease-out;
}
@media (min-width: 1081px) {
  .start-header.scroll-on .navbar-brand img {
    transition: all 0.3s ease-out;
  }
}
.start-header .container {
  max-width: 100%;
}
.start-header .container img {
  margin: 0 !important;
}

.navigation-wrap {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  transition: all 0.3s ease-out;
}
@media (max-width: 1080px) {
  .navigation-wrap {
    padding: 5px 0;
    z-index: 1000;
  }
  .navigation-wrap.bg-light {
    background-color: transparent !important;
  }
}

.navbar {
  font-size: 16px;
  font-size: 1rem;
}
@media (min-width: 1081px) {
  .navbar {
    padding-top: 15px;
  }
}
@media (min-width: 1400px) {
  .navbar {
    padding-left: 0.35em;
    padding-right: 0.35em;
  }
}
@media (min-width: 1600px) {
  .navbar {
    padding-left: 1.75em;
    padding-right: 1.75em;
  }
}
@media (max-width: 1080px) {
  .navbar {
    justify-content: space-between;
  }
}
.navbar-brand {
  padding: 0;
  margin: 0;
}
.navbar-brand img {
  display: block;
  transition: all 0.3s ease-out;
}
@media (min-width: 1300px) and (max-width: 1600px) {
  .navbar-brand {
    max-width: 13rem;
  }
}
@media (min-width: 1080px) and (max-width: 1299px) {
  .navbar-brand {
    max-width: 14rem;
  }
}
@media (max-width: 1080px) {
  .navbar-brand {
    max-width: 13rem;
  }
}
@media (max-width: 380px) {
  .navbar-brand {
    max-width: 10rem;
  }
}
.navbar-light .navbar-toggler-icon {
  width: 25px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after, .navbar-light .navbar-toggler-icon::before {
  width: 25px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after {
  top: 8px;
}
.navbar-light .navbar-nav .nav-link {
  display: inline-flex;
}
.navbar-collapse {
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 1081px) {
  .navbar-collapse {
    flex: auto;
    flex-wrap: nowrap;
  }
}
@media (min-width: 1070px) and (max-width: 1300px) {
  .navbar-collapse {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1080px) {
  .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    background: #fff;
    transition: 0.15s;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 45vw;
  }
  .navbar-collapse, .navbar-collapse > ul {
    z-index: 1000;
  }
  .navbar-collapse > ul {
    position: relative;
    justify-content: flex-start !important;
  }
  .navbar-collapse.show {
    display: block !important;
    left: 0;
  }
}
.navbar-overlay {
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
}
.navbar-overlay.active {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1081px) {
  .navbar-overlay {
    display: block;
  }
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active, .navbar-toggler:focus {
  outline: none;
}
@media (max-width: 1080px) {
  .navbar-toggler {
    display: block !important;
  }
}
.navbar-toggler .hamburger-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
}
.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
}
.navbar-toggler .hamburger-toggle .hamburger span {
  width: 100%;
  height: 4px;
  position: absolute;
  background: #000;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
  left: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 10%;
  transform-origin: 50% 50%;
  transform: translate(0% -50%) !important;
}
.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 0;
  top: auto;
  bottom: 0;
  transform-origin: 50% 50%;
}
.navbar-toggler .hamburger-toggle .hamburger.active span {
  position: absolute;
  margin: 0;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:first-child {
  top: 45%;
  transform: rotate(45deg);
}
.navbar-toggler .hamburger-toggle .hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:last-child {
  top: 45%;
  transform: rotate(-45deg);
}
.navbar-toggler.fixed {
  display: none !important;
  position: fixed;
  left: 43%;
  top: 2%;
  z-index: 1001;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span {
  background-color: #fff;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:first-child {
  transform: rotate(40deg);
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:last-child {
  transform: translateY(-18px) rotate(-40deg);
}
.navbar-toggler.fixed.active {
  display: block !important;
}
.navbar-nav.link {
  justify-content: space-between;
}
@media (min-width: 1081px) {
  .navbar-nav.link .nav-item {
    text-align: center;
  }
  .navbar-nav.link .nav-item .nav-link {
    display: block;
    font-weight: 600;
    line-height: 2.5;
    padding: 0 10px !important;
    margin: 0 20px;
    transition: 0.25s;
  }
}
@media (min-width: 1501px) and (max-width: 1800px) {
  .navbar-nav.link .nav-item .nav-link {
    margin: 0;
    padding: 0 0.5em !important;
  }
}
@media (min-width: 1081px) and (max-width: 1500px) {
  .navbar-nav.link .nav-item .nav-link {
    padding: 0 5px !important;
    margin: 0 10px;
  }
}
@media (min-width: 1081px) and (max-width: 1300px) {
  .navbar-nav.link .nav-item .nav-link {
    font-size: 16px;
    padding: 0 !important;
    margin: 0 5px;
  }
}
.navbar-nav.link .dropdown-menu {
  box-shadow: none;
  background: transparent;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-item {
  font-size: 16px;
  font-size: 1rem;
  background-color: transparent !important;
  color: #000 !important;
  line-height: 1.05;
  letter-spacing: 0px;
}
@media (min-width: 1081px) {
  .navbar-nav.link .dropdown-menu-list li .dropdown-item {
    white-space: normal;
    padding: 10px 6px;
    text-align: center;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-item:hover {
    background-color: #5f888b !important;
    color: white !important;
  }
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  text-align: center;
  color: #666;
  padding-left: 1.25rem;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item::before {
  content: "";
  display: block;
  width: 10px;
  height: 1px;
  background-color: #696969;
  position: absolute;
  top: 12.5px;
  left: 0;
}
@media (max-width: 1080px) {
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
    padding-top: 0;
    margin-top: -2px;
    padding-left: 20px;
    max-height: 55vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    position: static;
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
    font-size: 15px;
    font-size: 0.9375rem;
    background-color: transparent !important;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item:last-child {
    border-bottom: none;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier.active {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 1081px) {
  .navbar-nav.link {
    position: relative;
    justify-content: center;
    flex: 1;
    padding-left: 3.1578947368vw;
  }
  .navbar-nav.link .nav-link {
    text-transform: uppercase;
    flex-direction: column;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    left: 50%;
    padding: 0 !important;
    transform: translate3d(-50%, 0px, 0);
    min-width: 12em;
    max-height: 100vw;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu .dropdown-menu-list {
    width: 100%;
    padding: 10px;
    background: #f2f2f2;
    max-height: 100vw;
    border-radius: 5px;
    position: relative;
    top: 5px;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu .dropdown-menu-list::before {
    content: "";
    display: block;
    width: 70%;
    height: 2px;
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-nav.link .dropdown-item:hover {
    background-color: transparent;
    color: #000;
  }
}
@media (min-width: 1081px) and (max-width: 1600px) {
  .navbar-nav.link {
    flex: 1;
    justify-content: space-around;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.link {
    width: 100%;
    flex-direction: column;
  }
  .navbar-nav.link .nav-item {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1081px) {
  .navbar-nav.info {
    align-items: center;
    justify-content: center;
  }
  .navbar-nav.info .remark-social .icon {
    margin-left: 0.4210526316vw;
    margin-right: 0.4210526316vw;
  }
  .navbar-nav.info .remark-social svg, .navbar-nav.info .remark-social image {
    width: 2em;
    height: 2em;
  }
  .navbar-nav.info .nav-item-website {
    margin-left: 2.1052631579vw;
  }
}
@media (min-width: 1081px) and (max-width: 1600px) {
  .navbar-nav.info .remark-social svg, .navbar-nav.info .remark-social image {
    width: 1.75em;
    height: 1.75em;
  }
  .navbar-nav.info .nav-item-website {
    margin-left: 0vw;
  }
  .navbar-nav.info .nav-item-website .remark-social img {
    max-width: 16.8421052632vw;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.info {
    display: none;
  }
}
.navbar-mobile-icon {
  width: 80px;
  height: 40px;
  color: #000 !important;
  text-align: right;
  padding-right: 18px;
  position: absolute;
  right: 0;
  top: 5px;
  line-height: 40px;
}
.navbar-mobile-icon::after {
  display: none;
}
.navbar-mobile-icon.show .bi {
  transform: rotate(180deg);
}

.nav-link {
  position: relative;
  display: inline-block;
  color: #231815 !important;
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-weight: 500;
  padding: 5px 0 !important;
  transition: all 200ms linear;
}
@media (min-width: 1081px) {
  .nav-link {
    line-height: 3;
  }
}
@media (max-width: 1080px) {
  .nav-link {
    padding: 10px 0 !important;
  }
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}
.nav-item.active .nav-link, .nav-item:hover .nav-link {
  color: #f79000 !important;
}
.nav-item .icon {
  display: inline-block;
  vertical-align: top;
  flex: none;
  margin-top: -2px;
}
.nav-item .icon .bi-caret-down-fill {
  font-size: 12px;
  color: #000;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-left: 1.5em;
}
.nav-item img {
  margin: 0 !important;
}
@media (min-width: 1701px) and (max-width: 1800px) {
  .nav-item {
    margin-left: 1.35em !important;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .nav-item {
    margin-left: 0.5em !important;
  }
}
@media (max-width: 1080px) {
  .nav-item .dropdown-menu-list li {
    position: relative;
    margin-bottom: 0.25em;
  }
  .nav-item .dropdown-menu-list li .dropdown-item {
    padding-left: 0;
  }
  .nav-item .dropdown-menu-list li .navbar-mobile-icon {
    right: -1.3em;
    top: -4px;
  }
}
@media (max-width: 1080px) {
  .nav-item::after {
    display: none;
  }
  .nav-item::before {
    position: absolute;
    display: block;
    top: 24px;
    left: 5px;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
  }
}

.remark-item {
  display: block;
  border-radius: 10em;
  line-height: 2.25;
  border: #000000 1px solid;
  font-size: 1em;
  padding: 2px 20px !important;
  text-align: center;
}
.remark-item:hover {
  background-color: #5f888b;
  color: white;
}
.remark-wrapper .icon {
  margin-left: 1px;
  margin-right: 1px;
}
.remark-list:not(:last-child) {
  margin-right: 20px;
  padding-right: 20px;
}
.remark-list:not(:last-child)::after {
  content: "";
  display: block;
  width: 1px;
  height: 15px;
  background-color: #bfbfbf;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.remark-social {
  padding-top: 3px;
}

/* #Primary style
================================================== */
.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-item {
  font-size: 14px;
  line-height: 1.5;
  transition: all 200ms linear;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  background-color: #5f888b;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  min-width: 6.75em;
  max-height: 0;
  display: none;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item .dropdown-menu.show {
  display: block;
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0, 0);
}
@media (min-width: 1081px) {
  .nav-item .dropdown-menu {
    border-radius: 5px;
  }
}
@media (max-width: 1080px) {
  .nav-item .dropdown-menu .dropdown-menu-list li {
    border-bottom: 1px solid #e4e4e4;
    padding: 10px;
  }
  .nav-item .dropdown-menu.show {
    padding: 0 !important;
  }
}

@media (max-width: 1080px) {
  .nabar-icon-mobile {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5em;
  }
  .nabar-icon-mobile .icon {
    margin-left: 10px;
  }
  .nabar-icon-mobile .icon svg, .nabar-icon-mobile .icon image {
    width: 1.75em;
    height: 1.75em;
  }
}

.toggle-search {
  position: relative;
  top: 2px;
}
.toggle-search .icon-txt {
  --min-size: 18;
  --max-size: 26;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.toggle-search .icon-search {
  margin-top: -8px;
}

.search-formList {
  position: absolute;
  top: 6.65rem !important;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
  transition: 0.5s;
  display: none;
}
.search-formList.open {
  display: block;
}
@media (min-width: 1081px) and (max-width: 1600px) {
  .search-formList {
    top: 5rem !important;
  }
}
@media (min-width: 1101px) and (max-width: 1200px) {
  .search-formList {
    top: 5.25rem !important;
  }
}
@media (max-width: 1080px) {
  .search-formList {
    top: 4.45rem !important;
  }
}
.search-formInput {
  position: absolute;
  top: 0px;
  right: 0%;
  width: 100%;
  max-height: 60px;
  overflow: hidden;
  background-color: #e1e4e7;
  max-width: 40em;
}
.search-formInput fieldset {
  position: relative;
  margin: 0 60px 0 0;
  padding: 0;
  border: none;
}
.search-formInput input {
  display: block;
  height: 60px;
}
.search-formInput input[type=search] {
  width: 100%;
  padding: 0 5px 0 22px;
  background: transparent;
  border: none;
}
.search-formInput input[type=submit] {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: white;
  background: #5f888b;
  border: none;
  cursor: pointer;
}

footer {
  padding: 1em;
  background-color: #fff;
  border-top: #dfdbd2 2px solid;
}
footer .tag-item, footer .tag-item-current, footer .tag-item-date {
  font-size: 15px;
  background-color: #161616;
  color: #bfbfbf;
  padding: 5px 5px 6px;
}
@media (min-width: 1081px) {
  footer .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
footer .footer-info p {
  margin-bottom: 5px;
}
footer .footer-info .copyright {
  font-size: 15px;
  font-size: 0.9375rem;
  color: #bfbfbf;
}
footer .footer-info-list .icon {
  padding-right: 10px;
  margin-top: -6px;
}
@media (min-width: 1600px) {
  footer .footer-info-list {
    padding-left: 50px;
  }
}
footer .footer-info-left, footer .footer-info-left-row, footer .footer-info-left-item {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
footer .footer-info-left-row {
  justify-content: space-around;
  padding-top: 70px;
}
@media (min-width: 1081px) {
  footer .footer-info-left-row {
    padding-left: 4em;
    padding-right: 4em;
  }
}
@media (max-width: 1080px) {
  footer .footer-info-left {
    flex-direction: column;
  }
  footer .footer-info-left-row {
    padding-top: 1.5em;
  }
  footer .footer-info-left-row li {
    flex: 0 0 50%;
    margin-bottom: 1em;
  }
  footer .footer-info-left-item {
    justify-content: center;
  }
}
@media (max-width: 1080px) {
  footer .footer-info-right {
    padding-top: 1.5em;
  }
  footer .footer-info-right p {
    font-size: 13px !important;
  }
}
footer .footer-link-head {
  font-size: 18px;
  margin-bottom: 25px;
}
footer .footer-link-head::before {
  content: "";
  display: block;
  width: 23px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: -12px;
}
@media (min-width: 1081px) {
  footer {
    padding: 45px 25px 40px;
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  footer {
    padding-left: 2.6315789474vw;
    padding-right: 3.6842105263vw;
  }
}
@media (max-width: 1080px) {
  footer {
    padding-bottom: 4em;
  }
}

.iware {
  display: inline-block;
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 0px 8px;
}
.iware, .iware a {
  color: #646262;
}
.iware a:hover {
  text-decoration: underline;
}
.iware b {
  font-weight: 100;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
@media (min-width: 1081px) {
  .float-link .link-btn.nabar-icon-mobile {
    display: none;
  }
}
.float-link .link-btn.nabar-icon-mobile img {
  max-width: 100%;
}
@media (max-width: 1080px) {
  .float-link .link-btn.top {
    display: none;
  }
}
.float-link.active {
  right: 15px;
}
@media (max-width: 1600px) {
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1080px) {
  .float-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    bottom: 0;
    right: 0 !important;
    background: #5f888b;
    padding: 5px;
    margin: 0;
  }
  .float-link .link-btn {
    text-align: center;
    padding: 6px;
  }
  .float-link .link-btn,
.float-link .link-btn .link-btn-toggle {
    display: block;
    width: 100%;
  }
  .float-link .link-btn .link-btn-toggle {
    color: #fff;
  }
  .float-link .link-btn .icon {
    margin-left: 0;
  }
  .float-link .link-btn .icon small {
    font-size: 100%;
    padding-right: 5px;
  }
}

.talk-link {
  position: fixed;
  top: 200px;
  right: -100%;
  z-index: 10;
  transition: 0.3s;
  border-radius: 0.5em;
  background-color: #aac237;
  color: #fff !important;
  padding: 0.875em 1.5em 0.75em 0.75em;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.talk-link.active {
  right: -1%;
}
.talk-link p {
  --min-size: 16;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
  margin: 0;
  line-height: 1.25;
  letter-spacing: 2px;
}
@media (min-width: 1081px) {
  .talk-link .icon svg, .talk-link .icon image {
    width: 2em;
    height: 2em;
  }
}
@media (max-width: 1500px) {
  .talk-link {
    padding-left: 1.25em;
    padding-right: 1.5em;
  }
}
@media (max-width: 1080px) {
  .talk-link {
    top: auto;
    bottom: 0;
    width: 50%;
    left: 0;
    right: auto;
    flex-direction: row;
    border-radius: 0;
    box-shadow: none;
    padding: 13px 11px;
  }
  .talk-link p {
    writing-mode: initial;
  }
  .talk-link .icon {
    margin-left: 0.3em;
  }
  .talk-link svg, .talk-link image {
    width: 1.25em;
    height: 1.25em;
  }
}

.cookies {
  display: none;
  width: 100%;
  background: #5f888b;
  color: #fff;
  font-size: 16px;
  font-size: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 60;
}
.cookies p {
  font-weight: 400;
  text-align: center;
  line-height: 2;
}
@media (min-width: 1081px) {
  .cookies p {
    text-align: left;
    margin-bottom: 0;
  }
}
.cookies, .cookies .btn {
  color: #fff;
}
.cookies .btn {
  text-decoration: none !important;
  border-radius: 10px !important;
}
.cookies .btn:hover {
  background-color: #aac237 !important;
}
@media (min-width: 800px) {
  .cookies .btn {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
}
@media (max-width: 640px) {
  .cookies .btn {
    display: block;
    width: 100%;
    font-size: 16px;
    font-size: 1rem;
  }
}
.cookies-wrapper {
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1081px) {
  .cookies-wrapper {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
  }
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
.cookies.close-cookies {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}
@media (max-width: 810px) {
  .cookies.close-cookies {
    bottom: -2%;
  }
}
@media (min-width: 1081px) {
  .cookies .btn {
    font-size: 18px;
    font-size: 1.125rem;
    margin-left: 2.5em;
    padding: 0.5rem 2.5rem;
  }
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    border-top: none;
    margin: 0.5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 14px;
    font-size: 0.875rem;
  }
  .cookies.display {
    flex-direction: column;
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
.article,
article {
  width: 100%;
  font-size: 100%;
}
.article iframe,
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  .article iframe,
article iframe {
    height: 350px;
  }
}

.in-content {
  padding-top: 2.6315789474vw;
  padding-bottom: 2.6315789474vw;
}
@media (max-width: 1080px) {
  .in-content {
    padding-top: 3.90625vw;
    padding-bottom: 3.90625vw;
  }
}

.unreset {
  font-size: 18px;
}

.imgCenter-4-3 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 59.5%;
  overflow: hidden;
}
.imgCenter-4-3 img {
  max-height: none;
}

.g-recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1080px) {
  .g-recaptcha {
    margin-bottom: 1rem;
  }
}

.fw-400 {
  font-weight: 400 !important;
}
.fw-500, .remark-item {
  font-weight: 500 !important;
}
.fw-600, .intro_head, .searchResult-wrapper .searchResult-info-headTitle, .pagelist-group a, .link-wrap ul > li a {
  font-weight: 600 !important;
}
.fw-900, .aboutcircle__head, .card-btn-more, .pagination-wrap .pagination .page-link-arrow, .heading-title-article-slogan, .heading-title-txt, .heading-title-txt-en, .link-head {
  font-weight: 900 !important;
}

.boxShadow {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.bt-solid-c1 {
  border-top: 1px #ccc solid;
}

.mainContent {
  overflow: hidden;
}
.mainContent-wrap {
  padding-bottom: 2em;
  background-color: #f8f8f8;
}
@media (max-width: 1080px) {
  .mainContent-wrap {
    padding-top: 1em;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1081px) {
  .mainContent-info {
    width: 210px;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 210px);
    padding-left: 70px;
  }
}
@media (max-width: 1080px) {
  .mainContent-info {
    width: 100%;
  }
  .mainContent-info .link-wrap {
    display: none;
  }
}
.mainContent-list {
  max-width: 100%;
  flex: 1;
}
@media (max-width: 1080px) {
  .mainContent-list {
    padding: 0.5em 0.1em;
  }
}
@media (max-width: 1024px) {
  .mainContent {
    flex-direction: column;
  }
}

@media (min-width: 1101px) and (max-width: 1500px) {
  .mainRow {
    padding-left: 2.6315789474vw;
    padding-right: 3.6842105263vw;
  }
}

.link-wrap {
  border-radius: 1em;
  border: #aac237 1px solid;
  margin-top: 1.1em;
}
.link-wrap ul > li {
  padding-left: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.link-wrap ul > li:not(:last-child) {
  border-bottom: #aac237 1px solid;
}
.link-wrap ul > li .icon {
  position: relative;
  width: 100%;
  height: 40px;
  max-width: 40px;
  max-height: auto;
  align-items: center;
  justify-content: center;
}
.link-wrap ul > li .icon img, .link-wrap ul > li .icon svg {
  position: absolute;
  transition: 0.15s;
}
.link-wrap ul > li .icon-default {
  left: 0;
}
.link-wrap ul > li .icon-cur {
  left: -1000%;
}
.link-wrap ul > li > a {
  position: relative;
  display: block;
  --min-size: 15;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 1.5;
  color: #666666;
  padding: 0.5em 1em 0.25em 0.5em;
}
.link-wrap ul > li > a:after {
  content: "\f231";
  display: inline-block;
  font-family: bootstrap-icons !important;
  position: absolute;
  right: 1em;
}
.link-wrap ul > li > a:hover, .link-wrap ul > li > a:hover::after, .link-wrap ul > li > a.active, .link-wrap ul > li > a.active::after {
  color: #aac237;
}
.link-wrap ul > li > a:hover .icon-default, .link-wrap ul > li > a.active .icon-default {
  left: -1000%;
}
.link-wrap ul > li > a:hover .icon-cur, .link-wrap ul > li > a.active .icon-cur {
  left: 0;
}
.link-wrap ul > li > ol a {
  position: relative;
  display: block;
}
.link-wrap ul > li > ol li {
  background-color: #fff;
  border-bottom: #d3d3d3 1px dashed;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.link-wrap ul > li > ol li a {
  --min-size: 16;
  --max-size: 17;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #7c7b7b;
}
.link-wrap ul > li > ol li a::before {
  content: "\f231";
  font-family: bootstrap-icons !important;
  font-size: 0.65em;
  display: inline-block;
  vertical-align: middle;
  margin-top: -6px;
  margin-right: 20px;
}
.link-wrap ul > li > ol li a:hover, .link-wrap ul > li > ol li a.active {
  color: #5f888b;
}
.link-wrap .first_menu.open {
  display: block;
}
@media (min-width: 1081px) {
  .link-wrap .first_menu {
    display: none;
  }
}
@media (min-width: 1020px) and (max-width: 1500px) {
  .link-wrap a {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (max-width: 1024px) {
  .link-wrap {
    margin-bottom: 1rem;
  }
}
.link-head {
  font-size: 30px;
  font-size: 1.875rem;
  color: #5f888b;
  padding-left: 0.75rem;
  margin-bottom: 1.5rem;
}

.btn.list-group-mobile-btn {
  color: #fff !important;
  display: inline-flex;
  justify-content: space-between;
  border-radius: 3em !important;
}

.content-box {
  background-color: #f8f8f8;
}

.content-header {
  background-color: #fff;
  border-bottom: #e5e5e5 1px solid;
}
.content-header-wrap {
  justify-content: space-between;
}
@media (max-width: 1400px) {
  .content-header-wrap {
    padding-right: 1em;
  }
}
@media (max-width: 1080px) {
  .content-header-wrap {
    padding-left: 1em;
  }
}

.unit {
  position: relative;
  display: block;
  line-height: 1.5;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.unit .btn {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.unit-icon {
  padding-left: 1.5em !important;
  padding-right: 0;
}
.unit-icon .icon {
  margin-top: 0;
  position: absolute;
  left: 0;
  top: -0.1rem;
}
@media (max-width: 1080px) {
  .unit {
    text-align: left;
    font-size: 16px;
  }
  .unit-icon .icon {
    top: 0.85rem;
  }
}

.select_style::after {
  content: "\f282";
  font-family: "bootstrap-icons" !important;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  right: 8px;
  top: 1px;
}
.select_style select.form-control {
  background-color: #fff;
  border: 1px solid #e6eaea !important;
  text-align: left;
  border-radius: 0;
  height: 40px !important;
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.select_style select {
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
.select_style select,
.select_style input {
  border: none !important;
  height: 100% !important;
}
.select_style .form-control {
  border-radius: 0;
}
.select_style .disabled {
  background-color: #e9ecef;
}
.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.titleLine_db {
  position: relative;
  border-bottom: 2px solid #e5e5e5;
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding-bottom: 0.5em;
  padding-left: 0.5em;
}
.titleLine_db::after {
  content: "";
  width: 100px;
  height: 2px;
  background-color: #5f888b;
  position: absolute;
  left: 0.5em;
  bottom: -2px;
  z-index: 2;
}
.titleLine_db_center {
  text-align: center;
  padding-left: 0;
}
.titleLine_db_center::after {
  left: 50%;
  transform: translateX(-50%);
}

.titleLine_left {
  position: relative;
  border-bottom: #e5e5e5 1px solid;
  padding: 0.45em 0 0.45em 0.85em;
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.titleLine_left:before {
  content: "";
  width: 5px;
  height: 20px;
  background-color: #5f888b;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.heading-title {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: left;
  margin-bottom: 1.5em;
}
.heading-title-txt {
  --min-size: 24;
  --max-size: 30;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  text-transform: uppercase;
}
.heading-title-txt, .heading-title-txt-en {
  position: relative;
  padding-left: 1rem;
  padding-right: 0.5rem;
}
.heading-title-txt-en {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 0.25em;
  font-family: Helvetica;
}
.heading-title-txt-main {
  --min-size: 20;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding-left: 0.75rem;
}
@media (max-width: 1080px) {
  .heading-title {
    margin-bottom: 1em;
  }
  .heading-title-txt {
    margin-bottom: 0;
    padding-left: 0.5em;
  }
}
.heading-title-vertical .heading-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
.heading-title-vertical .heading-title::before {
  position: static;
  max-width: 200px;
  order: 1;
}
@media (max-width: 1080px) {
  .heading-title-vertical .heading-title::before {
    max-width: 4em;
  }
}
.heading-title-vertical .heading-title-txt {
  --min-size: 16;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #4e4f4f;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 1em;
}
@media (max-width: 1080px) {
  .heading-title-vertical .heading-title-txt {
    margin-bottom: 1em;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.heading-title-article {
  border: #5f888b 1px solid;
  border-top: none;
  padding: 0 2em 1em;
}
.heading-title-article::before, .heading-title-article::after {
  content: "";
  display: block;
  width: 1.3em;
  height: 1px;
  background-color: #5f888b;
  position: absolute;
  top: 0;
}
.heading-title-article::before {
  left: 0;
}
.heading-title-article::after {
  right: 0;
}
.heading-title-article-slogan {
  --min-size: 22;
  --max-size: 40;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #5f888b;
  position: relative;
  top: -0.65em;
  margin-bottom: -0.65em;
  line-height: 1.1;
  text-transform: uppercase;
}
.heading-title-article-txt {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  margin-bottom: 0.3em;
  color: #644689;
}

.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #404040;
  background-color: transparent;
  margin-bottom: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item {
  color: #777;
  text-transform: uppercase;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding-left: 0.15em;
  padding-right: 0.5em;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item.active {
  color: #5f888b;
}
@media (max-width: 1080px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 0;
    padding-top: 0.3em;
    padding-bottom: 0;
  }
}
.breadcrum-wrap .icon {
  margin-top: -6px;
  margin-right: 2px;
}

.pagination-wrap {
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.pagination-wrap .pagination {
  justify-content: center;
}
.pagination-wrap .pagination .page-link {
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #6e6d6d;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  border-color: transparent;
  border-radius: 50%;
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #fff;
  background-color: #5f888b;
  border-color: #5f888b;
}
.pagination-wrap .pagination .page-link-arrow {
  color: #000;
  border-color: #5f888b;
}
.pagination-wrap .pagination-num {
  border-radius: 2em;
  border: #5f888b 1px solid;
  margin-right: 0.5em;
  background-color: #fff;
}
.pagination-wrap .pagination-num .page-item:last-child .page-link {
  margin-right: 0;
}
.pagination-wrap .pagination-select {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-wrap .pagination-select select {
  text-align: center;
  appearance: auto;
  font-size: 15px;
  font-size: 0.9375rem;
}
.pagination-wrap .pagination-select .pagination-item {
  padding-left: 20px;
}
.pagination-wrap .pagination-mobile {
  max-width: 120px;
}
@media (min-width: 1081px) {
  .pagination-wrap .pagination-mobile .pagination-select {
    width: auto;
  }
  .pagination-wrap .pagination-mobile .pagination-select select {
    max-width: 45px;
  }
}
@media (max-width: 1080px) {
  .pagination-wrap {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.page-button .btn {
  flex: 1;
  padding-left: 0em !important;
  padding-right: 0em !important;
}
@media (min-width: 1081px) {
  .page-button .btn {
    flex: none;
    padding-left: 0.75em !important;
    padding-right: 0.75em !important;
    min-width: 9.5em;
    display: flex;
    justify-content: space-around;
  }
}

.pagelist-group {
  background-color: #f4f4f4;
  padding: 1em 0 1.5em;
}
@media (min-width: 1081px) {
  .pagelist-group {
    margin-bottom: 1em;
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .pagelist-group {
    padding-left: 2.6315789474vw;
    padding-right: 3.6842105263vw;
  }
}
.pagelist-group a {
  color: #666666;
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 1.2;
  display: block;
  width: 100%;
}
.pagelist-group .list-group-item {
  background-color: transparent;
  border-radius: 0 !important;
  border-right: #f79000 2px solid !important;
  text-align: center;
  margin-bottom: 0.5em;
}
@media (min-width: 1081px) {
  .pagelist-group .list-group-item {
    padding: 0.2em 1.95em;
  }
  .pagelist-group .list-group-item:first-child {
    border-left: #f79000 2px solid !important;
  }
}
@media (max-width: 1080px) {
  .pagelist-group .list-group-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.pagelist-group .list-group-item:hover a, .pagelist-group .list-group-item.active a {
  color: #f79000;
}
@media (max-width: 1080px) {
  .pagelist-group .list-group {
    flex-wrap: wrap;
  }
  .pagelist-group .list-group .list-group-item {
    flex: 0 0 33.3333333333%;
  }
  .pagelist-group .list-group .list-group-item:nth-child(3n) {
    border-right: none !important;
  }
}

.text-main {
  color: #5f888b !important;
}
.text-item {
  color: #f79000 !important;
}
.text-itemSub {
  color: #7d7d7d !important;
}
.text-underLine {
  text-decoration: underline !important;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-italic {
  font-style: italic;
}

.bg-itemSub {
  background-color: #6f6e6e !important;
}

.justify {
  text-align: justify;
}

.flex-none {
  flex: none !important;
}
@media (max-width: 640px) {
  .flex-none {
    width: 100%;
  }
}
.flex-auto {
  flex: auto !important;
}
.flex-1 {
  flex: 1 !important;
}

.tag-item, .tag-item-current, .tag-item-date {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  font-size: 14px;
  padding: 6px 12px;
  text-align: center;
  background-color: #eeeeee;
  color: #5b5a5a;
}
.tag-item-current, .tag-item-date {
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff;
  padding: 8px 16px;
  border-radius: 6px;
}
.tag-item-current {
  background-color: #00b7ee;
}
.tag-item-remark {
  background-color: #cbe6e6;
  color: #000;
}
.tag-item-date {
  background-color: #bfbf85;
}
.tag-item-wrap .tag-item ~ .tag-item, .tag-item-wrap .tag-item-current ~ .tag-item, .tag-item-wrap .tag-item-date ~ .tag-item, .tag-item-wrap .tag-item ~ .tag-item-current, .tag-item-wrap .tag-item-current ~ .tag-item-current, .tag-item-wrap .tag-item-date ~ .tag-item-current, .tag-item-wrap .tag-item ~ .tag-item-date, .tag-item-wrap .tag-item-current ~ .tag-item-date, .tag-item-wrap .tag-item-date ~ .tag-item-date {
  margin-left: 5px;
}
@media (min-width: 1081px) {
  .tag-item-wrap {
    padding-left: 15px;
  }
}
@media (max-width: 1080px) {
  .tag-item-wrap {
    padding-top: 10px;
  }
}

.detail-label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail-label-other {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}
.detail-blockquote {
  display: block;
  background: #fff;
  padding: 15px 20px 12px 25px;
  margin: 0 0 20px;
  position: relative;
  /*Font*/
  font-size: 16px;
  /*Borders - (Optional)*/
  border-left: 10px solid #5f888b;
  /*Box Shadow - (Optional)*/
  box-shadow: 2px 2px 15px #ccc;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  padding-left: 30px;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  position: absolute;
  top: -1px;
  left: 0;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 4px;
}
.radio_style input:checked + label::after {
  background-color: #5f888b;
}

.policy-section p,
.policy-section li {
  color: #333;
  font-size: 1rem;
  line-height: 1.5;
}
.policy-section .title {
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 0.5em;
}
.policy-section .policy-sec {
  margin-bottom: 40px;
}

.btn:hover {
  background-color: #5f888b !important;
  border-color: #5f888b !important;
  color: #fff !important;
}

.btn:hover {
  background-color: #5f888b !important;
  border-color: #5f888b !important;
  color: #fff !important;
}

.btn {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 100;
  border-radius: 2em !important;
  padding-left: 2.75em !important;
  padding-right: 2.75em !important;
}
@media (max-width: 1080px) {
  .btn {
    font-size: 16px;
    font-size: 1rem;
    padding-left: 1em;
    padding-right: 1em;
  }
}
.btn .icon {
  vertical-align: top;
  margin-top: -1px;
  margin-right: 3px;
}
.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}
.btn-skin {
  background-color: #dec9bc;
}
.btn-main {
  background-color: #5f888b;
  color: #fff;
}
.btn-white {
  background-color: #fff;
}
.btn-outline-dark {
  border: 1px solid #212529 !important;
}
.btn-outline-subsecondary {
  border: 1px solid #aac237 !important;
}
.btn-subsecondary {
  color: #fff !important;
}

.js-toggle-btn .btn {
  font-size: 16px;
  font-size: 1rem;
  border-radius: 20px;
  border: #eee 1px solid;
}
.js-toggle-btn .btn i {
  color: currentColor;
}
.js-toggle-btn .btn .icon {
  margin-top: 0;
}

.js-open {
  display: block !important;
  margin-top: 5px;
}

.box {
  align-items: stretch;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px;
}
.box p {
  margin-bottom: 0;
}
.box-head {
  flex: none;
  color: #717171;
  padding-left: 10px;
}
.box-text {
  flex: 1;
}

.card {
  flex-grow: 1;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  border: none;
  text-align: left;
}
@media (min-width: 1081px) {
  .card__2 {
    flex: 0 0 calc(50% - 2em);
    max-width: calc(50% - 2em);
  }
}
@media (min-width: 1081px) {
  .card__3 {
    flex: 0 0 calc(33.3333333333% - 2em);
    max-width: calc(33.3333333333% - 2em);
  }
}
@media (min-width: 1081px) {
  .card__4 {
    flex: 0 0 calc(25% - 2em);
    max-width: calc(25% - 2em);
  }
}
@media (max-width: 1080px) {
  .card {
    margin-left: 0.5em;
    margin-right: 0.5em;
    flex: 0 0 calc(50% - 1em);
    max-width: calc(50% - 1em);
  }
}
.card__full {
  flex: 0 0 calc(100% - 2em) !important;
  max-width: calc(100% - 2em) !important;
}
@media (min-width: 1081px) {
  .card__horizontal {
    flex-direction: row;
  }
}
.card-img-top {
  border-radius: 0px;
}
.card-img-top img {
  display: block;
  width: 100%;
}
.card-img-top + .card-caption {
  margin-top: 10px;
}
.card-time {
  font-family: "Open Sans";
}
@media (max-width: 1080px) {
  .card-time {
    margin-bottom: 1em;
  }
}
.card-title {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-weight: 900;
  line-height: 1.5;
}
.card-caption {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}
.card-items .card-time {
  position: relative;
  padding-bottom: 0.5em;
}
.card-items .card-time::after {
  content: "";
  display: block;
  width: 60px;
  height: 1.5px;
  background-color: #aac237;
  position: absolute;
  bottom: 0;
}
.card-body {
  padding: 1.25em;
}
.card-text {
  --min-size: 14;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #5b5a5a;
}
.card-btn {
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 900;
  background-color: #c9c9c9 !important;
  color: #fff !important;
  margin-bottom: 1em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.card-btn:hover {
  background-color: #5f888b !important;
}
.card-btn-more {
  font-size: 14px;
  font-size: 0.875rem;
  border-bottom: #333 1px solid;
  padding-bottom: 2px;
}
@media (max-width: 640px) {
  .card-btn {
    padding-left: 0;
    padding-right: 0;
  }
}
.card-linkNormal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.card-wrap {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
.card:hover .tag-item, .card:hover .tag-item-current, .card:hover .tag-item-date {
  background-color: #f79000;
  color: #fff;
}
.card:hover .card-btn {
  color: #252525;
}
.card:hover .card-btn::after {
  background-color: #252525;
}
@media (max-width: 1080px) {
  .card {
    margin-bottom: 1em;
  }
  .card-title {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-top: 0.25em;
  }
  .card-body {
    padding: 0.75em;
  }
  .card-caption {
    padding-top: 0.125em;
    padding-bottom: 0.25em;
  }
}

.empty-row {
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;
  max-width: 640px;
}
.empty-row .img-box {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
}
.empty-row .img-box img {
  display: block;
  width: 100%;
}
.empty-row .content {
  margin-top: 20px;
  text-align: center;
}
.empty-row .content .title {
  color: #222;
  font-size: 32px;
  font-family: "Open Sans";
  line-height: 1.2;
  margin: 0;
}
.empty-row .content .des {
  color: #999;
  font-size: 17px;
  line-height: 1.6;
  font-family: "Open Sans";
  margin: 0;
  margin-top: 10px;
}

@media (max-width: 640px) {
  .empty-row .content {
    margin-top: 30px;
  }
  .empty-row .content .title {
    font-size: 26px;
  }
  .empty-row .content .des {
    font-size: 16px;
    margin-top: 15px;
  }
}
.error-row {
  background: url("../images/error-background.svg") no-repeat;
  background-position: center top;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.error-row .img-box {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}
.error-row .img-box img {
  display: block;
  width: 100%;
}
.error-row .img-box .error-code {
  color: #555;
  font-size: 120px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 1;
  margin: 0;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translateX(-57%);
}
.error-row .content {
  margin-top: 50px;
  text-align: center;
}
.error-row .content .title {
  color: #444;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}
.error-row .content .des {
  margin-top: 25px;
}
.error-row .content .des p {
  color: #555;
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
}
.error-row .content .des p:not(:last-child) {
  margin-bottom: 5px;
}
.error-row .btn-row {
  margin-top: 40px;
  margin-bottom: 20px;
}
.error-row .btn-row .link {
  border-radius: 30px;
  background: #666666;
  color: #fff;
  display: block;
  font-size: 20px;
  padding: 15px 13px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-decoration: inherit;
  text-transform: uppercase;
  transition: all 0.4s;
  width: 7em;
  line-height: 1;
}
.error-row .btn-row .link:hover {
  background: #333;
}

@media (max-width: 1440px) {
  .error-row .img-box {
    max-width: 540px;
  }
  .error-row .img-box .error-code {
    font-size: 100px;
  }
  .error-row .btn-row {
    margin-top: 50px;
  }
}
@media (max-width: 1024px) {
  .error-row .img-box {
    max-width: 440px;
  }
  .error-row .img-box .error-code {
    font-size: 70px;
    top: 45%;
  }
  .error-row .btn-row {
    margin-top: 50px;
  }
}
@media (max-width: 640px) {
  .error-row .img-box .error-code {
    font-size: 50px;
    top: 45%;
  }
  .error-row .content {
    margin-top: 30px;
  }
  .error-row .content .title {
    font-size: 26px;
  }
  .error-row .content .des {
    margin-top: 15px;
  }
  .error-row .content .des p {
    font-size: 16px;
  }
  .error-row .btn-row {
    margin-top: 30px;
  }
  .error-row .btn-row .link {
    font-size: 17px;
    max-width: 170px;
  }
}
.searchResult-wrapper {
  position: relative;
  background-color: #f8f8f8;
  padding: 1.5em 2em 1.5em 1.5em;
}
.searchResult-wrapper .searchResult-info-list {
  background-color: #fff;
  padding: 1.25em 1.2em;
}
.searchResult-wrapper .searchResult-info-list:hover {
  background-color: #eee;
}
.searchResult-wrapper .searchResult-info-list:not(:last-child) {
  margin-bottom: 1em;
}
.searchResult-wrapper .searchResult-info-img {
  width: 100px;
}
@media (max-width: 1080px) {
  .searchResult-wrapper .searchResult-info-img {
    margin-top: 1px;
  }
}
.searchResult-wrapper .searchResult-info-head {
  line-height: 1.75;
}
@media (min-width: 1081px) {
  .searchResult-wrapper .searchResult-info-head {
    padding-left: 5px;
  }
}
.searchResult-wrapper .searchResult-info-headTitle {
  --min-size: 15;
  --max-size: 17;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #020202;
  margin-bottom: 0.35em;
}
.searchResult-wrapper .searchResult-info-headsubTitle {
  --min-size: 16;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #5d5d5e;
  line-height: 1.1;
}
@media (max-width: 1080px) {
  .searchResult-wrapper .searchResult-info-headsubTitle {
    line-height: 1.25;
  }
}
@media (max-width: 1080px) {
  .searchResult-wrapper {
    padding: 0.5em;
  }
}

:root {
  --fancy-slider-theme-color: #fff;
  --fancy-slider-font-family: sans-serif;
  --fancy-slider-button-side-offset: 32px;
  --fancy-slider-button-mobile-side-offset: 12px;
  --fancy-slider-button-mobile-bottom-offset: 0px;
  --fancy-slider-head-font-size-s: 36px;
  --fancy-slider-head-font-size: 48px;
  --fancy-slider-head-font-size-b: 60px;
  --fancy-slider-title-font-size: 33px;
  --fancy-slider-title-font-family: $OpenSans-300;
  --fancy-slider-title-line-height: 1;
  /* Card */
  --card-transition-duration: 500ms;
  --card-transition-delay: 250ms; /* delay background color */
  /* background */
  --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
  --fancy-slider-transition-background-delay: 1000ms; /* delay background color */
  /* other */
  --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
  --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
  --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
  /* scaling */
  --fancy-slider-transform-scale-slide: 1; /* before scale in slide - same as in js */
  --fancy-slider-transform-scale-img: 1; /* before scale in img - same as in js */
  /* nav */
  --fancy-slider-transition-button: 400ms; /*  */
  --fancy-slider-transition-button-arrow: 400ms; /*  */
  --fancy-slider-transition-button-circle: 400ms; /*  */
  --bs-aspect-ratio-mobile: 100% ;
}

@keyframes jump {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-15%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}

.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.index-content {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-top: 2em;
  padding-bottom: 2em;
}
@media (max-width: 1400px) {
  .index-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1080px) {
  .index-content {
    background-size: auto;
    padding: 1.5em 0.5em;
  }
}
@media (max-width: 1080px) {
  .index-pageWrapper {
    display: block;
    width: 100%;
  }
}
.index-pageSwiper {
  width: 100%;
  overflow: hidden;
}
.index-pageSwiper .swiper-wrapper {
  /* 通过改变animation-timing-function 制作弹性切换效果 */
  transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}
.index-pageSwiper .swiper-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: -2em;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next {
  width: 47px;
  height: 47px;
  overflow: hidden;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev::after,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next::after {
  display: none;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev svg path,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next svg path {
  fill: #5f888b;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev.swiper-button-disabled svg path,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next.swiper-button-disabled svg path {
  fill: #bfbfbf;
}
@media (min-width: 1081px) {
  .index-pageSwiper .swiper-button-wrapper .swiper-button-prev,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next {
    position: static;
  }
}
.index-pageArticle {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
@media (max-width: 1080px) {
  .index-pageArticle .btn-wrap {
    margin-top: 0;
    margin-bottom: 0.5em;
    padding-right: 0.5em;
  }
}
.index-pageService-article .articlebox {
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding-bottom: 3.6842105263vw;
}
.index-pageService-article .hellobabybox {
  text-align: center;
  animation: fadeInUser 2s ease-in-out alternate infinite;
}
@media (max-width: 1080px) {
  .index-pageService-article .hellobabybox {
    max-width: 9em;
    margin: auto;
  }
}
@media (min-width: 1081px) {
  .index-pageService-article {
    display: grid;
    grid-template-columns: 0.7fr auto;
    justify-content: flex-end;
  }
  .index-pageService-article .hellobabybox {
    padding-right: 2.1052631579vw;
  }
}
@media (min-width: 1081px) {
  .index-pageService .index-pageWrapper {
    margin-top: 2.3684210526vw;
  }
}
.index-pageService-decoration-lefttop .starbox {
  position: absolute;
  top: -6.3157894737vw;
}
@media (max-width: 1080px) {
  .index-pageService-decoration-lefttop .starbox {
    max-width: 5em;
    top: -15.625vw;
  }
}
.index-pageService-grid {
  margin-top: 3.1578947368vw;
  margin-bottom: 6.3157894737vw;
  background: url("../images/index-pageSer-grid-img.jpg") no-repeat center;
  background-size: 100%;
}
@media (max-width: 1080px) {
  .index-pageService-grid {
    background-position: bottom right;
    background-size: 1800px;
  }
}
.index-pageService-grid-middle .card {
  background: transparent;
  margin-bottom: 1.75em;
}
@media (min-width: 1600px) {
  .index-pageService-grid-middle .card {
    padding-left: 29px;
    padding-right: 29px;
  }
}
.index-pageService-grid-middle .card-title {
  display: inline-block;
  --min-size: 17;
  --max-size: 22;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-weight: 900;
  padding-bottom: 0.45em;
  margin-bottom: 0.45em;
  text-align: center;
}
.index-pageService-grid-middle .card-title::after {
  content: "";
  display: block;
  width: 81px;
  height: 2px;
  background-color: #000;
  margin: 0.45em auto auto;
}
.index-pageService-grid-middle .card-text {
  color: #666;
}
@media (max-width: 1080px) {
  .index-pageService-grid-middle .card-text {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.index-pageService-grid-middle .card-box {
  background-color: #ffeed7;
  box-shadow: 0 10px 0px 2px #f6e3c9;
  border-radius: 45% 45% 50% 50%/45% 45% 40% 40%;
  overflow: hidden;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 3em;
  padding-top: 2.5em;
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .index-pageService-grid-middle .card-box {
    padding-bottom: 2em;
  }
}
@media (max-width: 1080px) {
  .index-pageService-grid-middle .card-box {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
    padding-bottom: 1.5em;
  }
}
.index-pageService-grid-middle .card-img-top {
  padding: 0em 0.5em 0;
}
.index-pageService-grid-middle .card-img-circle {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  border: #ffeed7 4px solid;
  box-shadow: -1px 2px 0px -1px #fff;
}
.index-pageService-grid-middle .card-body {
  padding-left: 0;
  padding-right: 0;
}
.index-pageService-grid-middle .card:nth-child(2) .card-box {
  background-color: #cbe6e6;
  box-shadow: 0 10px 0px 2px #bedada;
}
.index-pageService-grid-middle .card:nth-child(2) .card-img-circle {
  border-color: #cbe6e6;
  box-shadow: 1px 2px 0px -1px #fff;
}
.index-pageService-grid-middle .card:nth-child(3) .card-box {
  background-color: #e8f0c1;
  box-shadow: 0 10px 0px 2px #e1e8c0;
}
.index-pageService-grid-middle .card:nth-child(3) .card-img-circle {
  border-color: #e8f0c1;
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .index-pageService-grid {
    padding: 0 3em 0 3em;
  }
}
@media (max-width: 1080px) {
  .index-pageService .title-wrap {
    padding-top: 1em;
  }
}
.index-pageNews {
  padding-bottom: 0;
}
.index-pageNews::before {
  content: "";
  display: block;
  width: 100%;
  height: 176px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  background-image: url("../images/index-pageNews-bgTop.png");
  position: absolute;
  top: 0;
}
.index-pageNews-decoration {
  position: absolute;
  z-index: 1;
}
.index-pageNews-decoration-left {
  left: -5.7894736842vw;
  top: -5.7894736842vw;
}
.index-pageNews-decoration-right {
  right: -8.9473684211vw;
  top: -8.9473684211vw;
}
@media (max-width: 1500px) {
  .index-pageNews-decoration-left {
    width: 5em;
    left: 0;
  }
  .index-pageNews-decoration-right {
    width: 8em;
    right: 0;
  }
}
@media (max-width: 1080px) {
  .index-pageNews-decoration {
    width: 3.5em;
  }
}
.index-pageNews .index-pageWrapper {
  background-color: #f7f5f1;
}
@media (min-width: 1081px) {
  .index-pageNews .index-pageWrapper {
    margin-top: 2.6315789474vw;
    padding-bottom: 4.2105263158vw;
  }
}
@media (max-width: 1500px) {
  .index-pageNews {
    padding-left: 0;
    padding-right: 0;
  }
  .index-pageNews .index-pageWrapper {
    padding-left: 4em;
    padding-right: 4em;
  }
}
@media (max-width: 1080px) {
  .index-pageNews .index-pageWrapper {
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-bottom: 1.5em;
  }
  .index-pageNews .index-pageWrapper .btn-wrap {
    margin-bottom: 0;
  }
}

@keyframes fadeInUser {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideInDownUser {
  from {
    transform: translate3d(0, -15%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.titlebox {
  display: inline-block;
  padding-right: 3.6842105263vw;
  padding-top: 3.9473684211vw;
  border-right: #231815 1px solid;
}

.starbox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 12em;
}
.starbox .starlist-left {
  animation: slideInDownUser 0.75s ease alternate infinite;
}
.starbox .starlist-middle {
  top: -3em;
  animation: slideInDownUser 0.75s 0.2s ease alternate infinite;
}
.starbox .starlist-right {
  top: -1.9em;
  animation: slideInDownUser 0.75s 0.4s ease alternate infinite;
}
.starbox-animated {
  position: absolute;
  top: -6.3157894737vw;
}
@media (max-width: 1080px) {
  .starbox-animated {
    max-width: 5em;
    top: -15.625vw;
  }
}

/* swiper index banner */
.swiper-bannerIn {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* Arrows */
}
.swiper-bannerIn .swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-bannerIn .swiper-image {
  height: 100%;
}
.swiper-bannerIn .swiper-image-inner {
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-blend-mode: overlay;
}
.swiper-bannerIn .swiper-slide {
  transition: all 1s linear;
  transition-delay: 0.5s;
  opacity: 0;
  overflow: hidden;
}
.swiper-bannerIn .swiper-slide.swiper-slide-next {
  opacity: 0;
}
.swiper-bannerIn .swiper-slide.swiper-slide-active {
  opacity: 1;
  width: 100% !important;
}
.swiper-bannerIn .swiper-slide.swiper-slide-active .swiper-image {
  overflow: hidden;
}
.swiper-bannerIn .swiper-image.swiper-image-active img {
  width: 100%;
}
@media (max-width: 1080px) {
  .swiper-bannerIn .swiper-image.swiper-image-active img {
    height: 14em;
    object-fit: cover;
    object-position: center;
  }
}
.swiper-bannerIn .swiper-image-inner.swiper-image-active {
  transition: all 1s linear;
  transition-delay: 1s;
  background-blend-mode: multiply;
}
.swiper-bannerIn .swiper-button-prev,
.swiper-bannerIn .swiper-button-next {
  width: 39px;
  transition: var(--fancy-slider-transition-button);
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}
.swiper-bannerIn .swiper-button-prev::after,
.swiper-bannerIn .swiper-button-next::after {
  display: none;
}
@media (max-width: 1080px) {
  .swiper-bannerIn .swiper-button-prev,
.swiper-bannerIn .swiper-button-next {
    width: 40px;
    opacity: 0.75;
  }
  .swiper-bannerIn .swiper-button-prev svg image,
.swiper-bannerIn .swiper-button-next svg image {
    height: 45px;
  }
}
.swiper-bannerIn .swiper-button-prev svg,
.swiper-bannerIn .swiper-button-next svg {
  display: block;
  transition: var(--fancy-slider-transition-button);
}
.swiper-bannerIn .swiper-button-prev {
  left: 0;
}
@media (max-width: 1080px) {
  .swiper-bannerIn .swiper-button-prev {
    left: -2%;
  }
}
.swiper-bannerIn .swiper-button-next {
  right: 0;
}
@media (max-width: 1080px) {
  .swiper-bannerIn .swiper-button-next {
    right: -2%;
  }
}
.swiper-bannerIn .swiper-pagination {
  width: 100%;
  position: absolute;
  text-align: center;
  transition: 300ms;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-bannerIn .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #000;
  opacity: 1;
}
.swiper-bannerIn .swiper-pagination-bullet-active {
  opacity: 1;
  background: transparent;
  transform: scale(2);
  border: #000 2px solid;
}
@media (min-width: 1081px) {
  .swiper-bannerIn .swiper-pagination {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: calc(100% - 4em) !important;
    min-height: 8em;
    bottom: auto !important;
    top: 50%;
    left: 2em !important;
  }
  .swiper-bannerIn .swiper-pagination .swiper-pagination-bullet {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
}

.page-banner {
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 900px) and (max-width: 1100px) {
  .page-banner {
    margin-top: 1%;
  }
}
.page-banner.index {
  overflow: visible;
  z-index: 3;
}
.page-banner-style {
  max-width: 16rem;
  position: absolute;
  right: 2.75rem;
  bottom: -8rem;
  z-index: 3;
}
@media (min-width: 1081px) and (max-width: 1600px) {
  .page-banner-style {
    max-width: 12rem;
    bottom: -4rem;
    right: 4rem;
  }
}
@media (max-width: 1080px) {
  .page-banner-style {
    max-width: 6.5rem;
    bottom: -5rem;
    right: 0.5rem;
  }
}
.pageGrid {
  display: grid;
  grid-column-gap: 0;
  grid-auto-rows: 0fr 0fr 0fr;
}
.pageGrid-list-frAuto {
  grid-template-columns: repeat(2, auto) 1fr;
}
.pageGrid-list-1to1 {
  grid-template-columns: 1fr 1fr;
}
.pageGrid-list-2to1 {
  grid-template-columns: 2fr 1fr 1fr;
}
.pageGrid-list-3to1 {
  grid-template-columns: 1fr 1fr 1fr;
}
.pageGrid-list-avg {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.pageGrid-list-1to2 {
  grid-template-columns: 1fr 1fr 2fr;
}
@media (max-width: 1080px) {
  .pageGrid {
    grid-template-columns: auto !important;
  }
}
.news-card .card:hover .tag-item-remark {
  background-color: #5f888b !important;
}
@media (max-width: 1500px) {
  .news-card {
    padding-left: 2.6315789474vw;
    padding-right: 3.6842105263vw;
  }
}
.news-card-wrap .card-body, .news-card-wrap .card-btn {
  width: 100%;
  border: #d9d9d9 2px solid;
  padding: 1.25em 1.5em;
}
@media (max-width: 1080px) {
  .news-card-wrap .card-body, .news-card-wrap .card-btn {
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
}
.news-card-wrap .card-body {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1081px) {
  .news-card-wrap .card-body {
    padding-top: 1.5em;
    padding-bottom: 0.5em;
  }
}
@media (max-width: 1080px) {
  .news-card-wrap .card-body {
    padding-top: 0.5em;
    padding-bottom: 0.25em;
  }
}
.news-card-wrap .card-body .card-time {
  margin-top: auto;
}
.news-card-wrap .card-btn {
  background-color: transparent !important;
  color: #000 !important;
  justify-content: flex-start;
  margin-top: -2px;
  margin-bottom: 0;
}
@media (max-width: 1080px) {
  .news-card-wrap .card-btn {
    padding-top: 0.5em;
    padding-bottom: 0.7em;
  }
}
@media (min-width: 1081px) {
  .news-card-wrap .card {
    margin-left: 2.1052631579vw;
    margin-right: 2.1052631579vw;
    flex: 0 0 calc(33.3333333333% - 4.2105263158vw);
    max-width: calc(33.3333333333% - 4.2105263158vw);
  }
}
.news-timelist {
  border-color: #dfdbd2;
  border-width: 2px 0 2px 0;
  border-style: solid;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 0.5em;
}
.news-timetxt {
  color: #666666;
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.news-content-article {
  line-height: 1.75;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media (max-width: 1500px) {
  .news-content {
    padding-left: 2.6315789474vw;
    padding-right: 3.6842105263vw;
  }
}

.about {
  /** Circle Styles */
}
.about-content {
  margin-top: 1em;
  margin-bottom: 1em;
}
@media (min-width: 1081px) {
  .about-content {
    margin-top: 2.6315789474vw;
    margin-bottom: 3.6842105263vw;
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .about-content {
    padding-left: 2.6315789474vw;
    padding-right: 3.6842105263vw;
  }
}
@media (max-width: 1080px) {
  .about-content {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .about-content .index-pageNews-decoration {
    top: -3em;
  }
}
.about-bgcolor {
  background-color: #f7f5f1;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.about-bgcolor::before {
  content: "";
  display: block;
  width: 100%;
  height: 176px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  background-image: url("../images/index-pageNews-bgTop.png");
  position: absolute;
  top: -2em;
}
@media (min-width: 1081px) {
  .about-bgcolor {
    padding-top: 3em;
    padding-bottom: 5em;
  }
  .about-bgcolor::before {
    top: -3em;
  }
  .about-bgcolor:last-child {
    padding-bottom: 3em;
  }
  .about-bgcolor .index-pageNews-decoration-left {
    width: 5em;
  }
  .about-bgcolor .index-pageNews-decoration-right {
    width: 6em;
    top: -6.3157894737vw;
  }
}
@media (max-width: 1080px) {
  .about-bgcolor {
    margin-top: 3em;
  }
}
.about-bgcolor:last-child {
  margin-bottom: 0;
}
.about-txt {
  --min-size: 17;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding-left: 1em;
  padding-right: 1em;
}
@media (min-width: 1081px) {
  .about-txt {
    padding-left: 1.75em;
  }
}
.aboutcircle {
  position: relative;
  display: block;
  margin: 2em 0;
  background-color: transparent;
  color: #222;
  text-align: center;
}
@media (min-width: 1081px) {
  .aboutcircle:after, .aboutcircle:before {
    content: "";
    display: block;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    border-radius: 50%;
  }
  .aboutcircle:after {
    background-color: #fff;
    position: relative;
    z-index: 1;
  }
  .aboutcircle::before {
    background-color: #cbe6e6;
    position: absolute;
    top: 0.35em;
    left: 0.35em;
    z-index: 0;
  }
}
@media (max-width: 1080px) {
  .aboutcircle {
    background-color: #fff;
    padding: 1em;
    margin-top: 0.75em;
    margin-bottom: 0;
    height: calc(100% - 2em);
    box-shadow: 7px 7px 0px 0px #cbe6e6;
  }
}
.aboutcircle__inner {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}
@media (min-width: 1081px) {
  .aboutcircle__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
.aboutcircle__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1081px) {
  .aboutcircle__wrapper {
    padding-top: 1.5em;
  }
}
.aboutcircle__icon {
  margin-bottom: 0.55em;
}
.aboutcircle__icon svg, .aboutcircle__icon image, .aboutcircle__icon .icon-svg {
  width: 4em;
  height: 4em;
}
@media (max-width: 1080px) {
  .aboutcircle__icon {
    margin-top: 0.75em;
  }
}
.aboutcircle__head {
  --min-size: 18;
  --max-size: 21;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  margin-bottom: 0.45em;
}
.aboutcircle__content {
  font-size: 15px;
  text-align: center;
}
@media (min-width: 1081px) {
  .aboutcircle__content {
    padding: 0 3em;
  }
}
@media (max-width: 1080px) {
  .aboutcircle__content {
    margin-bottom: 0.75em;
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .aboutcirlce-list {
    display: grid;
    grid-column-gap: 0;
    grid-auto-rows: 0fr 0fr 0fr;
    grid-template-columns: repeat(3, 33%);
  }
  .aboutcirlce-list > * {
    width: 100%;
  }
  .aboutcirlce-list .aboutcircle__wrapper {
    justify-content: center;
    padding-top: 0;
  }
}
.about-qalist-col {
  margin-bottom: 1.75em;
}
.about-qacard {
  border: #dfdbd2 3px solid;
  border-radius: 1.5em;
  padding: 2em 1em;
  max-width: 100%;
  margin-bottom: 0.75em;
  margin-left: 0;
  margin-right: 0;
}
@media (min-width: 1081px) {
  .about-qacard {
    padding-top: 3em;
    padding-left: 1.6em;
    padding-right: 1.6em;
    margin-left: 0.25em;
    margin-right: 0.25em;
    height: 100%;
  }
}
.about-qacard .card-head {
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 0.5em;
}
@media (min-width: 1081px) {
  .about-qacard .card-head {
    padding-top: 3em;
    padding-right: 6em;
  }
}
@media (max-width: 1080px) {
  .about-qacard .card-title {
    width: 100%;
    text-align: center;
  }
}
@media (min-width: 1081px) {
  .about-qacard .card-title {
    padding-right: 1em;
  }
}
.about-qacard .card-img-top {
  margin-bottom: 0.25em;
  text-align: center;
}
@media (min-width: 1081px) {
  .about-qacard .card-img-top {
    width: auto;
    position: absolute;
    right: 0;
    top: -1em;
  }
}
@media (max-width: 1080px) {
  .about-qacard .card-img-top svg, .about-qacard .card-img-top image {
    width: 3em;
    height: 3em;
  }
}

.icon-img,
.icon-svg {
  width: 4em;
  height: 4em;
}

.icon-svg-sm {
  width: 5em;
}

.intro_head {
  display: inline-block;
  color: #5f888b;
  --min-size: 18;
  --max-size: 26;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  letter-spacing: 0.15em;
  line-height: 1.5;
  margin-bottom: 5px;
}
@media (min-width: 1081px) {
  .intro_head {
    background: linear-gradient(transparent 60%, #f6df74 60%);
    line-height: 1.7em;
  }
}

.about-intro-head::after {
  content: "";
  display: block;
  position: absolute;
  top: -49px;
  left: 50%;
  width: 150px;
  height: 98px;
  margin-left: 320px;
  background: url("../images/pageimg-service001.png") left top no-repeat;
  background-size: contain;
  image-rendering: -webkit-optimize-contrast;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media (max-width: 1080px) {
  .about-intro-head::after {
    left: auto;
    right: 0;
  }
}
@media (max-width: 810px) {
  .about-intro-head::after {
    width: 80px;
  }
}
@media (max-width: 1080px) {
  .about-intro-head {
    margin-top: 1em;
  }
}

@media (min-width: 1081px) {
  .circleGrid-5 {
    display: grid;
    grid-column-gap: 0;
    grid-auto-rows: 0fr 0fr 0fr;
    grid-template-columns: repeat(5, 20%);
  }
  .circleGrid-5 > * {
    width: 100%;
  }
  .circleGrid-5 .aboutcircle__icon svg, .circleGrid-5 .aboutcircle__icon image {
    width: 5em;
    height: 5em;
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .circleGrid-5 {
    grid-template-columns: repeat(4, 25%);
  }
  .circleGrid-5 .aboutcircle {
    margin: 2em 0 0;
  }
}

.intro-title {
  --min-size: 22;
  --max-size: 31;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-weight: bold;
  padding-top: 4px;
}
.intro-title-sm {
  --min-size: 22;
  --max-size: 26;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}

.c-btn01 {
  display: flex;
  justify-content: center;
}
.c-btn01__txt {
  position: relative;
  line-height: 1.5;
  color: #483b35;
  font-weight: 700;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media (min-width: 768px) {
  .c-btn01__txt {
    font-size: 1.2rem;
  }
}
.c-btn01__ico {
  display: block;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 22px;
  height: 22px;
  margin: auto 0;
  border-radius: 50%;
  background-color: #e88f75;
}
.c-btn01__ico::before {
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  background: no-repeat center/contain;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2012%2012%22%20style%3D%22enable-background%3Anew%200%200%2012%2012%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23fff%7D%3C%2Fstyle%3E%3Cg%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1889%22%20class%3D%22st0%22%20d%3D%22M11.1%2C5.2L7.4%2C1.3C7%2C0.9%2C6.4%2C0.9%2C6%2C1.3L5.9%2C1.3c-0.4%2C0.4-0.4%2C1.1%2C0%2C1.5l2%2C2.1H1.5%20C1%2C5%2C0.5%2C5.5%2C0.6%2C6c0%2C0.5%2C0.4%2C0.9%2C1%2C1h6.4l-2%2C2.1c-0.4%2C0.4-0.4%2C1.1%2C0%2C1.5C6.1%2C10.9%2C6.3%2C11%2C6.6%2C11h0c0.3%2C0%2C0.5-0.1%2C0.7-0.3l3.8-4%20C11.5%2C6.3%2C11.5%2C5.7%2C11.1%2C5.2z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  content: "";
  transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
@media (min-width: 768px) {
  .c-btn01__ico {
    right: 45px;
    width: 25px;
    height: 25px;
    top: 5px;
    transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translateX(0);
  }
}
.c-btn01__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: 56px;
  padding: 12px 2.5em;
  line-height: 1.25;
  text-align: center;
  border-radius: 28px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 3px 0px 2px #eaeaea;
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
@media (min-width: 768px) {
  .c-btn01__link {
    max-width: 320px;
    min-height: 70px;
    padding: 12px 55px 9px;
    line-height: 1.5;
    border-radius: 35px;
    margin: 0 1em;
  }
  .c-btn01__link:hover {
    box-shadow: 0 2px 0px 2px #eaeaea;
  }
  .c-btn01__link:hover .c-btn01__ico {
    transform: translateX(5px);
  }
}
@media (max-width: 1080px) {
  .c-btn01__link {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.c-btn01__link_sm {
  background-color: rgba(234, 231, 231, 0.5);
  box-shadow: 0 3px 0px 2px #d4d4d4;
  padding-left: 0;
  padding-right: 0;
  max-width: 11em;
  min-height: 3.5em;
}
.c-btn01__link_sm .c-btn01__ico {
  right: 20px;
}
@media (min-width: 768px) {
  .c-btn01__link_sm:hover {
    box-shadow: 0 2px 0px 2px #d4d4d4;
  }
}

.support {
  width: 100%;
  background: url("../images/bg_circle_l.png") center 150px no-repeat;
}
.support-circle {
  width: 100%;
}
.support-circle::before {
  content: "";
  display: block;
  width: 100vw;
  height: 100vw;
  background: url("../images/bg_circle_l.png") center 150px no-repeat;
  position: absolute;
  left: 0;
}
.support-circle-r {
  width: 100%;
}
.support-circle-r::before {
  content: "";
  display: block;
  width: 100vw;
  height: 100%;
  max-height: 35em;
  background: url("../images/bg_circle_r.png") right 0px no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  background-size: contain;
}

.outlineBox {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0px 20px 10px -20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  position: relative;
  padding: 35px 60px 45px;
}
.outlineBox dl {
  padding: 10px 0;
  border-top: 1px solid #cccccc;
  display: flex;
}
.outlineBox dl, .outlineBox dl dd {
  margin-bottom: 0;
}
.outlineBox dl.outline-last {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}
.outlineBox dl dt {
  min-width: 11em;
  padding: 0 10px;
  margin-right: 30px;
  border-right: 2px solid #655d5b;
  font-size: 16px;
}
.outlineBox dl dd {
  flex: 1;
  font-size: 15px;
  padding-top: 6px;
}
@media (min-width: 1081px) {
  .outlineBox {
    max-width: 46em;
  }
  .outlineBox dt {
    line-height: 40px;
  }
}
@media (max-width: 1080px) {
  .outlineBox {
    padding: 30px 5%;
  }
  .outlineBox dl {
    display: block;
  }
  .outlineBox dl dt {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    font-weight: bold;
  }
}

.imgMore {
  max-width: 2em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 2em;
  z-index: 3;
}

.service__photo {
  z-index: 1;
}
.service__photo .card {
  border: #eeeeee 1px solid;
  box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.3);
  background: #fff;
  height: 0;
  padding: 8px 8px 30% 8px;
}
.service__photo .card img {
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.service__photo .card .imgMore {
  top: 100%;
  opacity: 0;
  transition: all 0.4s ease;
}
@media (min-width: 1081px) {
  .service__photo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 15px;
    margin-top: 0;
    padding-left: 2em;
    padding-right: 2em;
  }
  .service__photo .card {
    padding-bottom: 70%;
    margin: 0;
  }
  .service__photo .card::before {
    content: "";
    width: calc(100% - 0.5em);
    height: calc(100% - 0.5em);
    background-color: rgba(73, 58, 62, 0.35);
    pointer-events: none;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    opacity: 0;
    z-index: 1;
    position: absolute;
    left: 0.25em;
    top: 0.25em;
    border-radius: 10px;
  }
  .service__photo .card:hover::before {
    opacity: 1;
  }
  .service__photo .card:hover .imgMore {
    top: 0;
    opacity: 1;
    pointer-events: none;
  }
}
.service__photo__note {
  padding: 0em 2em;
  text-align: right;
}
.service__photo__note .icon {
  margin-top: -6px;
  margin-right: 1px;
}
.service__photo__note svg {
  height: 1.25em;
}
@media (max-width: 1080px) {
  .service__photo {
    margin-bottom: 0;
  }
}

.m-deco {
  position: absolute;
  z-index: -1;
  transform: rotate(-350deg);
  transform-origin: center;
  opacity: 0.8;
  animation: katakata2 6s step-start 0s infinite normal;
  z-index: 0;
}
.m-deco svg {
  display: block;
  height: 100%;
  transform: rotate(-98deg);
  transform-origin: center;
  width: 100%;
  fill: #eee;
}
.m-deco.red {
  opacity: 0.5;
}
.m-deco.red svg {
  fill: #f73636;
}
.m-deco.orange {
  opacity: 0.4;
}
.m-deco.orange svg {
  fill: #ff9200;
}
.m-deco.main {
  opacity: 0.4;
}
.m-deco.main svg {
  fill: #34a9b2;
}
.m-deco.gray svg {
  fill: #e3e3e3;
}

@keyframes katakata2 {
  0% {
    transform: translateY(2px) rotate(-6deg);
  }
  10% {
    transform: translate(0) rotate(0deg);
  }
  20% {
    transform: translate(0) rotate(6deg);
  }
  30% {
    transform: translateY(2px) rotate(0deg);
  }
  40% {
    transform: translateY(2px) rotate(-6deg);
  }
  50% {
    transform: translate(-2px) rotate(2deg);
  }
  60% {
    transform: translateY(-2px) rotate(0deg);
  }
  70% {
    transform: translate(0) rotate(-6deg);
  }
  80% {
    transform: translate(-2px) rotate(2deg);
  }
  90% {
    transform: translate(0) rotate(0deg);
  }
  100% {
    transform: translateY(2px) rotate(-6deg);
  }
}
@media (min-width: 1081px) {
  .m-deco {
    width: 158px;
    height: 158px;
    right: -50px;
    top: -75px;
  }
  .m-deco.gray {
    left: -30px;
    right: auto;
    top: auto;
    bottom: -35px;
    width: 125px;
    height: 125px;
  }
}
@media (max-width: 1080px) {
  .m-deco {
    width: 4em;
    height: 4em;
    bottom: 0em;
  }
  .m-deco.main {
    right: 0;
    bottom: auto;
    top: -1em;
  }
}