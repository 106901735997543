@mixin foot-flexSB{
  display: flex;
  justify-content: space-between;
}

footer {
  padding: 1em;
  background-color: #fff;
  border-top: #dfdbd2 2px solid;

  &,a{}

  .tag-item {
    font-size: 15px;
    background-color: #161616;
    color: #bfbfbf;
    padding: 5px 5px 6px;
  }

  .footer {
    &-wrapper{
      @include setMinRwd{
        @include foot-flexSB;
        align-items: flex-end;
      }
    }
    
    &-info{
      p{
        margin-bottom: 5px;
      }

      &-social{
        // img{
        //   max-width: 12em
        // }
        // @include setMinRwd{
        //   img{
        //     max-width: 8.5em
        //   }
        // }
      }

      .copyright{
        @include fontSize(15px);
        color: $colorSecondary;
      }

      &-list{
        .icon{
          padding-right: 10px;
          margin-top: -6px;
        }

        @include setStyleMinRwd(1600px){
          padding-left: 50px;
        }
      }

      &-left{
        &,
        &-row,
        &-item{
          flex: 1;
          display: flex;
          flex-wrap: wrap;
        }

        &-row{
          justify-content: space-around;
          padding-top: 70px;
          @include setMinRwd{
            padding-left: 4em;
            padding-right: 4em;
          }
        }

        @include setMaxRwd{
          flex-direction: column;
          &-row{
            padding-top: 1.5em;
            li{
              flex: 0 0 50%;
              margin-bottom: 1em;
            }
          }
          &-item{
            justify-content: center;
          }
        }
      }

      &-right{
        @include setMaxRwd{
          padding-top: 1.5em;
          p{
            font-size: 13px !important;
          }
        }
      }
    }

    &-link{
      &-head{
        font-size: 18px;
        margin-bottom: 25px;
        &::before{
          content: '';
          display: block;
          @include setSize(23px,2px);
          background-color: #fff;
          position: absolute;
          bottom: -12px;
        }
      }
    }
  }

  @include setMinRwd{
    padding: 45px 25px 40px;
  }
  
  @include setWidthRwd(1101px,1500px){
    padding-left: get-vw(50px);
    padding-right: get-vw(70px);
  }

  @include setMaxRwd{
    padding-bottom: 4em;
  }
}

.iware {
  display: inline-block;
  // background: #e5e5e5;
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 0px 8px;
  &, a{
    color: #646262;
  }
  
  a {
      &:hover {
          text-decoration: underline;
      }
  }

  b{
    font-weight: 100;
  }
}

// Gotop 使用
.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
  .link-btn {
    a {
      &:hover {
          opacity: 0.65;
      }
    }

    &.nabar-icon-mobile{
      @include setMinRwd{
        display: none;
      }
      img{
        max-width: 100%;
      }
    }

    &.top{
      @include setMaxRwd{
        display: none;
      }
    }
  }

  &.active {
      right: 15px;
  }

  @include setStyleRwd(1600px){
    &.active {
        right: 10px;
    }
    
  }

  @include setMaxRwd{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    bottom: 0;
    right: 0 !important;
    background: $colorMain;
    padding: 5px;
    margin: 0;
    .link-btn {
      text-align: center;
      padding: 6px;

      &,
      .link-btn-toggle{
        display: block;
        width: 100%;
      }

      .link-btn-toggle{
        color: #fff;
      }

      .icon{
        margin-left: 0;
        small{
          font-size: 100%;
          padding-right: 5px;
        }
      }
    }
  }
}

// 社群聊天使用
.talk-link{
  position: fixed;
  top: 200px;
  right: -100%;
  z-index: 10;
  transition: 0.3s;
  @include radius(0.5em);
  background-color: $colorSubs;
  color: #fff !important;
  // padding: 1.5em 2em 1.5em 1.25em;
  padding: 0.875em 1.5em 0.75em 0.75em;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.active{
    right: -1%;
  }

  p{
    --min-size: 16;
    --max-size: 20;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    margin:0;
    line-height: 1.25;
    letter-spacing: 2px;
    // @include setMinRwd{
    //   & ~ .icon{
    //     margin-top: 0.75em;
    //   }
    // }
  }

  @include setMinRwd{
    .icon{
      svg, image{
        width: 2em;
        height: 2em;
      }
    }
  }

  @include setStyleRwd(1500px){
    padding-left:1.25em;
    padding-right: 1.5em;
  }

  @include setMaxRwd{
    top: auto;
    bottom: 0;
    width: 50%;
    left: 0;
    right: auto;
    flex-direction: row;
    border-radius: 0;
    box-shadow: none;
    padding: 13px 11px;
    
    p{
      writing-mode: initial;
    }

    .icon{
      margin-left: 0.3em;
    }

    svg, image{
      width: 1.25em;
      height: 1.25em;
    }
  }
}

// Cookie Privacy
.cookies {
  display: none;
  width: 100%;
  background: $colorMain;
  color: #fff;
  @include fontSize(16px);
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 60;
  
  p {
    font-weight: 400;
    text-align: center;
    line-height: 2;

    @include setMinRwd{
      text-align: left;
      margin-bottom: 0;
    }
  }

  &, .btn{
    color: #fff;
  }

  .btn {
    text-decoration: none !important;
    border-radius: 10px !important;
    &:hover{
      background-color: $colorSubs !important;
    }

    @include setStyleMinRwd(800px){
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;
    }

    @include mobile{
      display: block;
      width: 100%;
      @include fontSize(16px);
    }
  }

  &-wrapper{
    @extend %setFlex;
    flex-direction: column;
    align-items: center;

    @include setMinRwd{
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: center;
    }
  }
  
  &.display {
    display: block;
    animation: cookies 1s 1;
  }

  &.close-cookies {
    transition: all 0.2s ease-out;
    transform: translate3d(0, 100%, 0);
    @include pad {
      bottom: -2%;
    }
  }

  @include setMinRwd{
    .btn {
      @include fontSize(18px);
      margin-left: 2.5em;
      padding: 0.5rem 2.5rem;
    }
  }

  @include pad {
    width: calc(100% - 1em);
    border-top: none;
    margin: .5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include fontSize(14px);
    
    &.display { 
      flex-direction: column;
    }
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}