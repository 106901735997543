$card-left: 1em;
$card-right: 1em;
$card-bottom: 1em;

$mobile-card-left: 0.5em;
$mobile-card-right: 0.5em;

$card-btn :#aaaaaa;
$card-btn-active :#252525;


.box{
    @extend %setFlex;
    align-items: stretch;
    position: relative;
    z-index: 1;
    @include fontSize(18px);
    padding: 10px;

    p{
        margin-bottom:0;
    }
    
    &-head{
        flex: none;
        color: #717171;
        padding-left: 10px;
    }

    &-text{
        flex: 1;
    }
}

.card{
    flex-grow: 1;
    margin-left: $card-left;
    margin-right: $card-right;
    margin-bottom: $card-bottom;
    border: none;
    text-align: left;

    // Grid
    &__2{
        @include setMinRwd{
            flex: 0 0 calc(100% / 2 - ($card-left + $card-right));
            max-width: calc(100% / 2 - ($card-left + $card-right));
        }
    }

    &__3{
        @include setMinRwd{
            flex: 0 0 calc(100% / 3 - ($card-left + $card-right));
            max-width: calc(100% / 3 - ($card-left + $card-right));
        }
    }

    &__4{
        @include setMinRwd{
            flex: 0 0 calc(100% / 4 - ($card-left + $card-right));
            max-width: calc(100% / 4 - ($card-left + $card-right));
        }
    }

    @include setMaxRwd{
        margin-left: $mobile-card-left;
        margin-right: $mobile-card-right;
        flex: 0 0 calc(100% / 2 - ($mobile-card-left + $mobile-card-right));
        max-width: calc(100% / 2 - ($mobile-card-left + $mobile-card-right));
    }

    &__full{
        flex: 0 0 calc(100% - ($card-left + $card-right)) !important;
        max-width: calc(100% - ($card-left + $card-right)) !important;
    }

    &__horizontal{
        @include setMinRwd{
            flex-direction: row;
        }
    }
    // End

    &-img-top{
        @include radius(0px);

        img{
            display: block;
            width: 100%;
        }

        & + .card-caption{
            margin-top: 10px;
        }
    }

    &-time{
        @extend %setFlex-center;
        font-family: $OpenSans-300;
        @include setMaxRwd{
            margin-bottom: 1em;
        }
    }

    &-title{
        --min-size: 18;
        --max-size: 20;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        font-weight: 900;
        line-height: 1.5;
    }

    &-caption{
        padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    }

    &-items{
        .card-time{
            position: relative;
            padding-bottom: 0.5em;
            &::after{
                content: '';
                display: block;
                @include setSize(60px,1.5px);
                background-color: $colorSubs;
                position: absolute;
                bottom: 0;
            }
        }
    }

    &-body{
        padding: 1.25em;
    }

    &-text{
        --min-size: 14;
        --max-size: 16;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        color: $colorText;
    }

    &-btn{
        width: 100%;
        @include fontSize(16px);
        font-weight: 900;
        background-color: #c9c9c9 !important;
        color: #fff !important;
        margin-bottom: 1em;
        padding-top: 0.75em;
        padding-bottom: 0.75em;

        &:hover{
            background-color: $colorMain !important
        }

        &-more{
            @include fontSize(14px);
            border-bottom: #333 1px solid;
            padding-bottom: 2px;
            @extend .fw-900;
        }

        @include mobile{
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-linkNormal{
        position: absolute;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    // 框
    &-wrap{
        @extend %setFlex;
        width: 100%;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    &:hover{
        .tag-item{
            background-color: $colorItem;
            color: #fff;
        }

        .card-btn{
            color: $card-btn-active;
            &::after{
                background-color:  $card-btn-active;
            }
        }
    }

    @include setMaxRwd{
        margin-bottom: 1em;
        
        &-title{
            @include fontSize(15px);
            margin-top: 0.25em;
        }

        &-body{
            padding: 0.75em; 
        }

        &-caption {
            padding-top: 0.125em;
            padding-bottom: 0.25em;
        }
    }
}